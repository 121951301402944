import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import expandIcon from "../../assets/images/select.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    fontSize: "20px",
    padding: "0",
    fontFamily: "inherit",
    margin: "25px 0"
  },
  heading: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "20px",
    padding: "0",
    fontFamily: "inherit",
  },
  single: {
    boxShadow: "none",
  },
  details: {
    padding: "0",
  },
}));

export default function Faq() {
  const classes = useStyles();

    function ExpandIcon() {
        return(
            <img src={expandIcon} alt='expandIcon' />
        )
    }

  return (
    <div className="faq">
      <Header />
      <Navigation />
      <div className="content sample-page">
        <div className="size">
          <h2 className="title">FAQ's</h2>
          <div className={classes.root}>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.single}>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.heading}
              >
                <Typography className={classes.heading}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography className={classes.content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
