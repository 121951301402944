import "./style.css";
import { NavLink } from "react-router-dom";
import burger from "./../../assets/images/burger_menu.svg"
import {useState} from "react";

export default function Navigation() {
    let [burgerState,setBurgerState] = useState(true)
    let [toggleCategory,setToggleCategory] = useState(false)
    let toggleMenu = () =>{
        setBurgerState(!burgerState)
    }
  return (

    <div>
        <div className="navigation web-style">
            <div className="size">
                <div className='categories'>
                    <div className={"toggleCategory"} onClick={ () => {setToggleCategory(!toggleCategory)}} >
                        All Categories
                    </div>
                    {toggleCategory ?
                        <div className="toggleCategoryItems">
                            <NavLink to={"/sellers"} className="categoryItem">Sellers</NavLink>
                            <NavLink to={"/"} className="categoryItem">Lorem</NavLink>
                            <NavLink to={"/"} className="categoryItem">Lorem</NavLink>

                        </div>
                    : null }
                </div>

                <ul>
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">New Arrivals</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Deals For You</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Weekly Offers</NavLink>
                    </li>
                    <li>
                        <NavLink to="/products">My Products</NavLink>
                    </li>
                    <li>
                        <NavLink to="/">Discounts</NavLink>
                    </li>
                </ul>
            </div>
        </div>


        <div className="navigation tablet-style mobile-style">
            <div className="size">
                <div className='categories'>
                    <div className={"toggleCategory"} onClick={ () => {setToggleCategory(!toggleCategory)}} >
                        All Categories
                    </div>
                    {toggleCategory ?
                        <div className="toggleCategoryItems">
                            <NavLink to={"/sellers"} className="categoryItem">Sellers</NavLink>
                            <NavLink to={"/"} className="categoryItem">Lorem</NavLink>
                            <NavLink to={"/"} className="categoryItem">Lorem</NavLink>

                        </div>
                        : null }
                </div>

                <nav className="navbar">
                    <ul className={burgerState == true ? "nav-links" : "nav-links active"}>
                        <li>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/">New Arrivals</NavLink>
                        </li>
                        <li>
                            <NavLink to="/">Deals For You</NavLink>
                        </li>
                        <li>
                            <NavLink to="/">Weekly Offers</NavLink>
                        </li>
                        <li>
                            <NavLink to="/">My Products</NavLink>
                        </li>
                        <li>
                            <NavLink to="/">Discounts</NavLink>
                        </li>
                    </ul>
                    <div id="burger" onClick={toggleMenu}>
                        <img src={burger} alt="burger"/>
                    </div>
                </nav>
            </div>
        </div>
    </div>
);
}
