import "./style.css";
import Product from "../product/Product";

export default function Recommended({data}) {
  return (
    <div className="recommended">
        <div className='size'>

            <div className='recommended-title'>
                <p>Explore Awesome Products</p>
                <h3>Recommended For You</h3>
            </div>

        <div className='products'>
        <Product name={"ring"} price={"100"}/>
            {/* <NavLink to='products'> */}
            
            {/* </NavLink> */}
            {/* <Product />
            <Product />
            <Product />
            <Product /> */}

        </div>

        <button className='other-products'>Explore Other Products</button>
        </div>
    </div>
  );
}
