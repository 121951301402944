import "./style.css";

export default function Advertisement() {
    return(
        <div className='size'>
            <div className='advertisement'>
                <div className='content'>
                    <h2>Lorem ipsum</h2>
                    <h2>Get 50% Off Sitewide</h2>
                </div>
                <button className='start-shopping'>Start Shopping</button>
            </div>
        </div>
    );
}