import "./style.css";
import { NavLink } from "react-router-dom";

export default function Copyright(){
  return (
    <div className="copyright">
        <ul>
            <li>
                <NavLink to='/'>
                Legal noice
                </NavLink>
            </li>
            <li>
                <NavLink to='/privacyPolicy'>
                    Privacy Policy
                </NavLink>
            </li>
            <li>
                <NavLink to='/'>
                    Cookie Preferences
                </NavLink>
            </li>
            <li>
                <NavLink to='/'>
                    Accessibility Policy
                </NavLink>
            </li>
        </ul>
    </div>
  );
}