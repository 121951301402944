import "./style.css";
import { NavLink } from "react-router-dom";
import Copyright from "../../components/copyright/Copyright";

export default function Footer() {
  return (
    <div className="footer">
      <div className="size">
        <div className="footer-menus">
          <ul>
            <li className="menu-title">About</li>

            <li>
              <NavLink to="/aboutUs">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/howItWorks">How it Works</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact us</NavLink>
            </li>
          </ul>

          <ul>
            <li className="menu-title">Opportunities</li>

            <li>
              <NavLink to="/">Consignment</NavLink>
            </li>
            <li>
              <NavLink to="/">Careers</NavLink>
            </li>
            <li>
              <NavLink to="/">Ambassador program</NavLink>
            </li>
          </ul>

          <ul>
            <li className="menu-title">Locations</li>

            <li>
              <NavLink to="/">United States of America</NavLink>
            </li>
            <li>
              <NavLink to="/">Canada</NavLink>
            </li>
            <li>
              <NavLink to="/">United Kingdom</NavLink>
            </li>
          </ul>

          <ul>
            <li className="menu-title">Support</li>

            <li>
              <NavLink to="/faq">FAQs</NavLink>
            </li>
            <li>
              <NavLink to="/">Shipping & Returns</NavLink>
            </li>
          </ul>
        </div>

        <div className="sm-links">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0678 0C22.6861 0 24 1.31391 24 2.93222V21.0678C24 22.6861 22.6861 24 21.0678 24H16.0771V14.9571H19.199L19.793 11.0843H16.0771V8.57095C16.0771 7.51144 16.5961 6.47864 18.2605 6.47864H19.95V3.18145C19.95 3.18145 18.4167 2.9198 16.9508 2.9198C13.8905 2.9198 11.8902 4.77459 11.8902 8.13248V11.0843H8.48836V14.9571H11.8902V24H2.93222C1.31391 24 0 22.6861 0 21.0678V2.93222C0 1.31391 1.31386 0 2.93222 0H21.0678V0Z"
                fill="#302F2F"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 9.27686C11.4614 9.27686 10.935 9.43656 10.4872 9.73577C10.0394 10.035 9.69035 10.4603 9.48425 10.9578C9.27815 11.4554 9.22423 12.0029 9.3293 12.5311C9.43437 13.0594 9.69371 13.5446 10.0745 13.9254C10.4554 14.3062 10.9406 14.5655 11.4688 14.6706C11.997 14.7757 12.5445 14.7218 13.0421 14.5157C13.5396 14.3096 13.9649 13.9605 14.2641 13.5127C14.5633 13.0649 14.723 12.5385 14.723 11.9999C14.7222 11.278 14.435 10.5859 13.9245 10.0754C13.414 9.56492 12.7219 9.27774 12 9.27686Z"
              fill="#302F2F"
            />
            <path
              d="M15.2035 5.06348H8.79649C7.80679 5.06462 6.85797 5.45828 6.15815 6.1581C5.45833 6.85792 5.06467 7.80674 5.06353 8.79644V15.2035C5.06467 16.1932 5.45833 17.142 6.15815 17.8418C6.85797 18.5416 7.80679 18.9353 8.79649 18.9364H15.2035C16.1932 18.9353 17.142 18.5416 17.8419 17.8418C18.5417 17.142 18.9353 16.1932 18.9365 15.2035V8.79644C18.9353 7.80674 18.5417 6.85792 17.8419 6.1581C17.142 5.45828 16.1932 5.06462 15.2035 5.06348ZM12 16.1616C11.1769 16.1616 10.3723 15.9175 9.68794 15.4602C9.00357 15.0029 8.47017 14.353 8.15519 13.5925C7.84021 12.8321 7.75779 11.9953 7.91837 11.1881C8.07895 10.3808 8.4753 9.63927 9.05731 9.05726C9.63932 8.47525 10.3808 8.0789 11.1881 7.91832C11.9954 7.75774 12.8321 7.84016 13.5926 8.15514C14.353 8.47012 15.003 9.00352 15.4602 9.6879C15.9175 10.3723 16.1616 11.1769 16.1616 12C16.1603 13.1033 15.7215 14.1611 14.9413 14.9412C14.1611 15.7214 13.1033 16.1603 12 16.1616ZM16.573 8.46908C16.3698 8.46908 16.1711 8.40884 16.0022 8.29598C15.8332 8.18312 15.7015 8.0227 15.6237 7.835C15.5458 7.6473 15.5254 7.44075 15.5649 7.24144C15.6045 7.04212 15.7022 6.85901 15.8458 6.71523C15.9894 6.57145 16.1723 6.47345 16.3716 6.43364C16.5709 6.39382 16.7774 6.41397 16.9652 6.49153C17.1531 6.56909 17.3137 6.70059 17.4268 6.8694C17.5398 7.03821 17.6004 7.23676 17.6006 7.43996C17.6009 7.57507 17.5745 7.70892 17.523 7.83382C17.4714 7.95873 17.3958 8.07225 17.3003 8.16788C17.2049 8.26351 17.0915 8.33938 16.9667 8.39115C16.8419 8.44291 16.7081 8.46956 16.573 8.46956V8.46908Z"
              fill="#302F2F"
            />
            <path
              d="M23.2728 5.98736C23.2715 5.58023 23.2342 5.17402 23.1614 4.77344C23.0683 4.28432 22.9373 3.80768 22.703 3.3656C22.4591 2.89983 22.1415 2.47647 21.7627 2.11184C21.1905 1.55167 20.4836 1.14832 19.7102 0.940642C18.9984 0.748642 18.2702 0.708322 17.5402 0.709282C17.5377 0.706065 17.5362 0.702247 17.5358 0.698242H6.46031C6.46031 0.702082 6.46031 0.705922 6.46031 0.709282C6.04511 0.716962 5.62895 0.713602 5.21615 0.764962C4.76347 0.812823 4.31781 0.912681 3.88799 1.06256C3.21951 1.30914 2.61617 1.70513 2.12399 2.22032C1.55981 2.79367 1.15353 3.50322 0.944629 4.28C0.755509 4.98656 0.714229 5.70704 0.713269 6.4328L0.705589 17.553C0.719029 18.0546 0.723349 18.561 0.807829 19.0582C0.897589 19.5862 1.02575 20.1003 1.27055 20.5794C1.59614 21.2213 2.06009 21.7829 2.62895 22.2238C3.03502 22.5475 3.49504 22.7969 3.98783 22.9606C4.71359 23.1949 5.46239 23.2846 6.22223 23.289C6.69647 23.2918 7.16975 23.3038 7.64399 23.3019C11.0875 23.2875 14.5315 23.3269 17.975 23.2808C18.4299 23.2708 18.8832 23.2227 19.3301 23.1368C20.1751 22.9879 20.9615 22.6051 21.6 22.0318C22.3574 21.356 22.8686 20.5294 23.0995 19.5315C23.2507 18.8792 23.2882 18.2173 23.2949 17.5534V17.421C23.2944 17.3691 23.2747 6.13616 23.2728 5.98736ZM20.3745 15.2034C20.3729 16.5743 19.8276 17.8887 18.8582 18.8582C17.8888 19.8277 16.5745 20.3731 15.2035 20.3749H8.79647C7.4254 20.3732 6.11097 19.8278 5.14148 18.8584C4.17199 17.8889 3.6266 16.5744 3.62495 15.2034V8.79632C3.6266 7.42526 4.17199 6.11082 5.14148 5.14133C6.11097 4.17184 7.4254 3.62645 8.79647 3.6248H15.2035C16.5746 3.62645 17.889 4.17184 18.8585 5.14133C19.828 6.11082 20.3734 7.42526 20.375 8.79632L20.3745 15.2034Z"
              fill="#302F2F"
            />
          </svg>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0678 0C22.6861 0 24 1.31391 24 2.93222V21.0678C24 22.6861 22.6861 24 21.0678 24H8.87025C9.37823 23.1433 10.0235 21.9307 10.2809 20.9408C10.437 20.3397 11.0812 17.8872 11.0812 17.8872C11.5 18.6859 12.7237 19.3621 14.0253 19.3621C17.8996 19.3621 20.6909 15.7994 20.6909 11.3723C20.6909 7.12884 17.2279 3.95367 12.7719 3.95367C7.22855 3.95367 4.28461 7.6748 4.28461 11.7268C4.28461 13.6108 5.28759 15.9563 6.89194 16.7031C7.13531 16.8163 7.26548 16.7664 7.3215 16.5313C7.36411 16.3527 7.58072 15.4803 7.67831 15.0745C7.70948 14.9448 7.69406 14.8333 7.5892 14.7061C7.05844 14.0625 6.63323 12.8788 6.63323 11.7747C6.63323 8.94169 8.77856 6.2003 12.4335 6.2003C15.589 6.2003 17.799 8.35069 17.799 11.4262C17.799 14.901 16.0441 17.3084 13.761 17.3084C12.5 17.3084 11.5562 16.2657 11.8588 14.9871C12.221 13.4602 12.9227 11.8125 12.9227 10.7103C12.9227 9.7237 12.3932 8.90086 11.2974 8.90086C10.0083 8.90086 8.97309 10.2344 8.97309 12.0204C8.97309 13.1581 9.35737 13.9275 9.35737 13.9275C9.35737 13.9275 8.08449 19.31 7.85119 20.3124C7.59291 21.4215 7.69406 22.9835 7.80637 23.9998L7.80689 24H2.93222C1.31391 24 0 22.6861 0 21.0678V2.93222C0 1.31391 1.31386 0 2.93222 0H21.0678V0Z"
                fill="#302F2F"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <Copyright />
      </div>
    </div>
  );
}
