import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../config";
import validator from "validator";
import { useHistory } from "react-router-dom";


export default function ForgotPassword() {
    const history = useHistory();
    const [userEmail, setUserEmail] = useState({
        email: ''
    });
    const [emailError, setEmailError] = useState();

    let handleEmail = (e) => {
        setUserEmail({email:e.target.value});
    };
    let forgotFormSend = () => {
        let email = userEmail.email;
        !validator.isEmail(email) ? setEmailError(false) : setEmailError(true);
        axios.post(`${config.url}/send-code`, userEmail, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                setEmailError('');
                localStorage.setItem("verificationEmail", email);

                console.log(res)
                if (res.data.error){
                    if(res.data.error.email){
                        setEmailError(res.data.error.email);
                    }
                }else{
                    history.push("/verification");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <div className="forgotPassword">
            <Header/>
            <Navigation/>
            <div className='content'>
                <div></div>
                <div className='form'>
                    <h2 className='pageTitle'>Forgot password?</h2>
                    <input type="email" placeholder="Youremail@gmail.com" name='email' onChange={handleEmail} className={emailError && emailError !== true ? 'errorInput' : ''}/>
                    <span
                        className="error"
                        style={{ display: emailError && emailError !== true ? "block" : "none" }}
                        >
                        {emailError}
                      </span>
                    <button className='submit' onClick={forgotFormSend}>SUBMIT</button>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
