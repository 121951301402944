import './style.css';
import Header from '../../components/header/Header';
import Navigation from '../../components/navigation/Navigation';
import Footer from '../../components/footer/Footer';
import onlinestoreImg from '../../assets/images/online-store.png';
import paymentImg from '../../assets/images/payment.png';
import cartImg from '../../assets/images/cart.png';

export default function HowItWorks() {
    return (
        <div className="howItWorks">
        <Header />
        <Navigation />
        <div className="sample-page">
          <div className="size">
            <h2 className="title">How it works</h2>
            <div className='content'>
                <div className='step-rows'>
                    <div className='step-row'>
                        <img src={onlinestoreImg} alt='onlineStore' />
                        <div className='second-step'>
                            <div className='step-number'>
                                <h2>1</h2>
                            </div>
                            <h4>Lorem ipsum dolor</h4>
                            <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id sed quam fringilla nisl mauris bibendum accumsan. Dignissim odio sed amet in libero. Nibh convallis lectus at sapien amet eu. </h5>
                        </div>
                    </div>
                    <div className='step-row'>
                        <img src={paymentImg} alt='onlineStore' />
                        <div className='second-step'>
                            <div className='step-number'>
                                <h2>2</h2>
                            </div>
                            <h4>Lorem ipsum dolor</h4>
                            <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id sed quam fringilla nisl mauris bibendum accumsan. Dignissim odio sed amet in libero. Nibh convallis lectus at sapien amet eu. </h5>
                        </div>
                    </div>
                    <div className='step-row'>
                        <img src={cartImg} alt='onlineStore' />
                        <div className='second-step'>
                            <div className='step-number'>
                                <h2>3</h2>
                            </div>
                            <h4>Lorem ipsum dolor</h4>
                            <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id sed quam fringilla nisl mauris bibendum accumsan. Dignissim odio sed amet in libero. Nibh convallis lectus at sapien amet eu. </h5>
                        </div>
                    </div>
                </div>

            </div>
          </div>
        </div>
  
        <Footer />
      </div>
    )
}
