import Advertisement from "../../components/advertisement/Advertisement";
import Banner from "../../components/banner/Banner";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Recommended from "../../components/recommended/Recommended";
import Sale from "../../components/sale/Sale";
import TopProduct from "../../components/topProducts/TopProducts";
import "./style.css";
import {useEffect} from "react";

export default function Home() {

    useEffect(() => {
        let storage = localStorage.getItem('CardProducts')
        if(!storage){
            localStorage.setItem('CardProducts', JSON.stringify([]))
        }
    }, [])
 
  return (
    <div>
      <Header />
      <Navigation />
      <Banner />
      <Recommended />
      <Advertisement />
      <Sale />
      <Advertisement />
      <TopProduct />
      <Footer />
    </div>
  );
}
