import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import Home from "./app/containers/home/Home";
import SignUp from "./app/containers/signUp/SignUp";
import LogIn from "./app/containers/logIn/LogIn";
import ForgotPassword from "./app/containers/forgotPassword/ForgotPassword";
import Verification from "./app/containers/verification/Verification";
import newPassword from "./app/containers/newPassword/NewPassword";
import Products from "./app/containers/products/Products";
import PrivacyPolicy from "./app/containers/privacyPolicy/PrivacyPolicy";
import AboutUs from "./app/containers/aboutUs/AboutUs";
import Cart from "./app/containers/cart/Cart";
import EditProfile from "./app/containers/editProfile/EditProfile";
import Payment from "./app/containers/payment/Payment";
import addProducts from "./app/containers/addProducts/addProducts";
import Contact from "./app/containers/contact/Contact";
import HowItWorks from "./app/containers/howItWorks/HowItWorks";
import Faq from "./app/containers/Faq/Faq";
import Seller from "./app/containers/seller/Seller";
import Sellers from "./app/containers/sellers/sellers";
import ProductSingle from "./app/containers/productSingle/ProductSingle";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/signUp" component={SignUp} />
        <Route exact path="/logIn" component={LogIn} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/verification" component={Verification} />
        <Route exact path="/newPassword" component={newPassword} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/aboutUs" component={AboutUs} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/editProfile" component={EditProfile} />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/addProducts" component={addProducts} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/howItWorks" component={HowItWorks} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/seller" component={Seller} />
        <Route exact path="/sellers" component={Sellers} />
        <Route exact path="/productSingle" component={ProductSingle} />
      </BrowserRouter>
    </div>
  );
}

export default App;
