import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import CartItem from "../../components/cartItem/cartItem";
import buyIcon from "../../assets/images/Vector.png";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import Product from "../../components/product/Product";

export default function Cart() {
    let sum = 0;

    const [cardData, setCardData] = useState();
    let storageCard = JSON.parse(localStorage.getItem("CardProducts"));
    let [sumPrice, setSumPrice] = useState(0);
    useEffect(() => {
        setCardData(storageCard);
    }, [])

    useEffect(() => {
        storageCard.map((item) => {
            if (item) {
                let parsenum = parseInt(item.GUIDEPRICE)
                sum = sumPrice += parsenum;
            }

        })

        setSumPrice((prev) => {
            return sum
        })

    }, [])


    let deleteCardItem = (pr_code) => {

        let deletedCard = storageCard.map((item)=> {
            let parsenum = parseInt(item.GUIDEPRICE)
            if (item.PRODUCTCODE === pr_code){
               let deletedPrice =  sumPrice - parsenum
                setSumPrice(deletedPrice)
            }
            return item
        }).filter((item) => {
            return item.PRODUCTCODE !== pr_code;
        })
        localStorage.setItem("CardProducts", JSON.stringify(deletedCard))
        setCardData(deletedCard)
    }


    return (
        <div className="cart-page">
            <Header/>
            <Navigation/>
            <div className="content sample-page">
                <div className="size">
                    <h2 className="title">Cart</h2>
                    <div className="cart">
                        <div className="cart-products">
                            {cardData ? cardData.map((item, index) => {
                                return (
                                    <CartItem
                                        key={index}
                                        title={item.PRODUCTNAME}
                                        price={item.GUIDEPRICE}
                                        image={item.IMAGE}
                                        deleteCardItem={() => {
                                            deleteCardItem(item.PRODUCTCODE)
                                        }}
                                    />
                                );
                            }) : "Cart is empty"}
                        </div>
                        <div className="order-summary">
                            <h4>Order Summary</h4>
                            <div className="summary">
                                <div className='subtotal'>
                                    <p>Subtotal</p>
                                    <p>${sumPrice}</p>
                                </div>
                                <p>Shipping</p>
                                <div>
                                    <p>New York, United States</p>
                                    <p>FREE</p>
                                </div>
                            </div>
                            <div className="cart-total">
                                <h6>Cart Total: ${sumPrice}</h6>
                            </div>

                            <div className="buy-now">
                                <NavLink to="/" className={sumPrice <= 0 ? "disabled" : ""}>
                                    <span>Checkout</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
