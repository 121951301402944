import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import ProfileNavigation from "../../components/profileNavigation/ProfileNavigation";
import Card from "../../assets/images/card.png";
import {useEffect, useState} from "react";
import validator from "validator";
import axios from "axios";
import config from "../../../config";

export default function Payment() {
    const [cardDetails, setCardDetails] = useState({
        credit_card: '',
        exp_month: '',
        exp_year: '',
        cvc: '',
        card_name: '',

    });


    const [numberError, setNumberError] = useState();
    const [nameError, setNameError] = useState();
    const [monthError, setMonthError] = useState();
    const [yearError, setYearError] = useState();
    const [cvcError, setCvcError] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const [cards, setCards] = useState([]);

    useEffect(() => {
        axios.get(`${config.url}/card`, {
            headers: {
                Authorization:
                    `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                console.log(res)
                setCards([...cards, ...res.data]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    let handleCardDetails = (e) => {
        // console.log(e.target.value)

        setCardDetails({
            ...cardDetails,
            [e.target.name]: e.target.value,
        });
    };
    let deleteCard = (token) => {
        // console.log(token)
        axios.delete(`${config.url}/delete/card/${token}`, {
            headers: {
                Authorization:
                    `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                "Content-Type": "application/json",
            },
        }).then((res) => {

            // console.log(res)
            let arr = cards.filter(value => value.id !== token)
            setCards(arr)

        })
            .catch((err) => {
                console.log(err);
            });
    }
    let cardDetailsFormSend = () => {
        // console.log(cardDetails)
        axios.post(`${config.url}/card`, cardDetails, {
            headers: {
                Authorization:
                    `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {

                console.log(res)
                if (res.data.error) {
                    setSuccessMessage("");

                    if (res.data.error.card_name) {
                        setNameError(res.data.error.card_name)
                    }
                    if (res.data.error.credit_card) {
                        setNumberError(res.data.error.credit_card)
                    }
                    if (res.data.error.cvc) {
                        setCvcError(res.data.error.cvc)
                    }
                    if (res.data.error.exp_month) {
                        setMonthError(res.data.error.exp_month)
                    }
                    if (res.data.error.exp_year) {
                        setYearError(res.data.error.exp_year)
                    }
                    if(res.data.error.message){
                        setSuccessMessage(res.data.error.message)
                    }

                } else {
                    setNameError("")
                    setNumberError("")
                    setCvcError("")
                    setMonthError("")
                    setYearError("")

                    setSuccessMessage(res.data.message);
                    setCards([...cards, res.data.card]);
                    console.log(res.data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    let emptyInputs = () => {
        let inputs = document.querySelectorAll("input");
        inputs.forEach((element, index, array) => {
            element.value = "";
        });
    }
    return (
        <div className="paymentPage">
            <Header/>
            <Navigation/>
            <div className="payment profile-content">
                <ProfileNavigation/>

                <div className='edit-profile-block container-fluid'>
                    <div className="section-title">
                        <h2>Payment</h2>
                    </div>
                    {/*<div className='card-type'>*/}
                    {/*    <label>*/}
                    {/*        <input type='radio' name='card_type' />*/}
                    {/*        <span className='radioBtn'></span>*/}
                    {/*        Credit card*/}
                    {/*    </label>*/}
                    {/*    <label>*/}
                    {/*        <input type='radio' name='card_type' />*/}
                    {/*        <span className='radioBtn'></span>*/}
                    {/*        Cash*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <div className='cards'>
                        <div className="cards-overflow-parent">
                            <div className="cards-child cards-overflow  mg-b-0">
                                {cards?.map((card, index) => {
                                    let token = card.id;
                                    return (
                                        <div className="connected-card" key={index}>
                                            <div className='delete-card' onClick={() => {
                                                deleteCard(token)
                                            }}>
                                                <span>Delete card</span>
                                                <svg width="28" height="24" viewBox="0 0 28 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.9733 5.25L6.80225 18.75" stroke="#757575" strokeWidth="2"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M21.9733 18.75L6.80225 5.25" stroke="#757575" strokeWidth="2"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                            {/*<img src={Card} className='cardImg' alt='cardImg'/>*/}
                                            <div className="card">
                                                <div className="empty"></div>
                                                <div className="card-number">
                                                    <span>****</span> <span>****</span> <span>****</span>
                                                    <span>{card.last4 ? card.last4 : "****"}</span>
                                                </div>
                                                <div className="bottom-info">
                                                    <div className="name">{card.name ? card.name : "Name "}</div>
                                                    <div className="exp d-flex flex-column">
                                                        <div className="title">Expire date</div>
                                                        <div className="data">
                                                            <span>{card.month ? card.month : "MM"}</span> / <span>{card.year ? card.year : "YY"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="cvv">
                                                        <div className="title">CVV</div>
                                                        <div className="data">
                                                            ***
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <div className='add-new' onClick={emptyInputs}>
                                <p>Add new card</p>
                            </div>
                        </div>

                    </div>


                    <div className='card-information'>
                        <div>

                            <input type='number' maxLength={16} name='credit_card' placeholder='Card number'
                                   onChange={handleCardDetails}
                                   className={numberError && numberError !== true ? 'errorInput' : ''}
                            />
                            <span
                                className="error"
                                style={{display: numberError && numberError !== true ? "block" : "none"}}
                            >
                                {numberError}
                                </span>
                        </div>
                        <div>
                            <input type='text' name='card_name' placeholder='Name'
                                   onChange={handleCardDetails}
                                   className={nameError && nameError !== true ? 'errorInput' : ''}
                            />
                            <span
                                className="error"
                                style={{display: nameError && nameError !== true ? "block" : "none"}}
                            >
                        {nameError}
                        </span>
                        </div>
                        <div className="d-flex">
                            <div style={{marginRight: 20}}>
                                <input type='number' maxLength={2} name='exp_month' placeholder='MM'
                                       style={{width: 90, marginRight: 20}}
                                       onChange={handleCardDetails}
                                       className={monthError && monthError !== true ? 'errorInput' : ''}
                                />
                                <span
                                    className="error"
                                    style={{display: monthError && monthError !== true ? "block" : "none"}}
                                >
                                {monthError}
                                </span>
                            </div>
                            <div>
                                <input type='number' maxLength={2} name='exp_year' placeholder='YY' style={{width: 90}}
                                       onChange={handleCardDetails}
                                       className={yearError && yearError !== true ? 'errorInput' : ''}
                                />
                                <span
                                    className="error"
                                    style={{display: yearError && yearError !== true ? "block" : "none"}}
                                >
                                {yearError}
                                </span>
                            </div>
                        </div>
                        <div className='cvv w-100'>
                            <div>
                                <input type='number' maxLength={3} name='cvc' placeholder='CVV'
                                       onChange={handleCardDetails}
                                       className={cvcError && cvcError !== true ? 'errorInput' : ''}
                                />
                                <span
                                    className="error"
                                    style={{display: cvcError && cvcError !== true ? "block" : "none"}}
                                >
                                {cvcError}
                                </span>
                            </div>
                            <span>3 or 4 digit code</span>
                        </div>
                    </div>
                    <div className='save d-flex flex-column align-center ml-auto'>
                        <button className='save-details btn-shadow' onClick={cardDetailsFormSend}>SAVE</button>
                        <div style={{marginTop: 15}}>
                            {successMessage}
                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
}
