import "./style.css";
import productTestImage from "../../assets/images/product.png"
import axios from "axios";
import config from "../../../config";
import { useHistory } from "react-router-dom";

export default function Product({name, price, image, code, index}) {
    const history = useHistory();

    let rediretProductSingle = (id) => {
        axios
            .get(`${config.url}/getById/${id}`, {
                headers: {
                    Authorization:
                        `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                // console.log(res);
                localStorage.setItem("ProductDetails", JSON.stringify(res.data));
                history.push("/productSingle");
            })
            .catch((err) => {
                console.log(err, "error");
            });

    }

    return (
        <div className="product pointer" key={index} onClick={() => {
            rediretProductSingle(code)
        }}>
            <img src={image} alt="product"/>
            {/*<img src={productTestImage} alt="product"/>*/}
            <p className='product-title'>{name}</p>
            <p className='price'>${price}</p>
        </div>
    );
}
