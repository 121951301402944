import "./style.css";
import {NavLink, useHistory} from "react-router-dom";
import profilePic from "../../assets/images/profile-picture.png";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../config";

export default function ProfileNavigation(props) {
    const history = useHistory();
    let logOut = () => {
        localStorage.setItem("token", '');
        history.push("/login");
    }
    let [userInfo, setUserInfo] = useState({
        avatar: ''
    });
    let [profileImageState,setProfileImageState] = useState({img : userInfo.avatar})
    useEffect(()=>{
        axios.get(`${config.url}/get-user`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                'Content-Type': 'application/json'
            }
        }).then((res)=>{
            console.log(res)
            setUserInfo({
                avatar: res.data.image,
            })
            setProfileImageState({
                img:res.data.image
            })
        }).catch((err)=>{
            console.log(err)
        })
    },[])

    return(
        <div className="profile-info">
          <div className='profile-pic-content'>
          <label className="profile-pic" htmlFor="avatar_input">
          <img src={profileImageState.img} alt="profilePic" />
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M22.875 6H20.1075L18.6075 3H12.8925L11.3925 6H6V4.5H2.25V6H1.125C0.826631 6 0.540483 6.11853 0.329505 6.3295C0.118526 6.54048 0 6.82663 0 7.125L0 19.875C0 20.1734 0.118526 20.4595 0.329505 20.6705C0.540483 20.8815 0.826631 21 1.125 21H22.875C23.1734 21 23.4595 20.8815 23.6705 20.6705C23.8815 20.4595 24 20.1734 24 19.875V7.125C24 6.82663 23.8815 6.54048 23.6705 6.3295C23.4595 6.11853 23.1734 6 22.875 6ZM3 5.25H5.25V6H3V5.25ZM23.25 19.875C23.25 19.9745 23.2105 20.0698 23.1402 20.1402C23.0698 20.2105 22.9745 20.25 22.875 20.25H1.125C1.02554 20.25 0.930161 20.2105 0.859835 20.1402C0.789509 20.0698 0.75 19.9745 0.75 19.875V7.125C0.75 7.02554 0.789509 6.93016 0.859835 6.85983C0.930161 6.78951 1.02554 6.75 1.125 6.75H11.8575L13.3575 3.75H18.1425L19.6425 6.75H22.875C22.9745 6.75 23.0698 6.78951 23.1402 6.85983C23.2105 6.93016 23.25 7.02554 23.25 7.125V19.875Z"
                  fill="white"
                />
                <path d="M6 7.5H5.25V19.5H6V7.5Z" fill="white" />
                <path
                  d="M15.75 7.5C14.5633 7.5 13.4033 7.85189 12.4166 8.51118C11.4299 9.17047 10.6609 10.1075 10.2067 11.2039C9.7526 12.3003 9.63378 13.5067 9.86529 14.6705C10.0968 15.8344 10.6682 16.9035 11.5074 17.7426C12.3465 18.5818 13.4156 19.1532 14.5795 19.3847C15.7433 19.6162 16.9497 19.4974 18.0461 19.0433C19.1425 18.5892 20.0795 17.8201 20.7388 16.8334C21.3981 15.8467 21.75 14.6867 21.75 13.5C21.75 11.9087 21.1179 10.3826 19.9926 9.25736C18.8674 8.13214 17.3413 7.5 15.75 7.5ZM15.75 18.75C14.7117 18.75 13.6966 18.4421 12.8333 17.8652C11.9699 17.2883 11.297 16.4684 10.8996 15.5091C10.5023 14.5498 10.3983 13.4942 10.6009 12.4758C10.8035 11.4574 11.3035 10.5219 12.0377 9.78769C12.7719 9.05346 13.7074 8.55345 14.7258 8.35088C15.7442 8.14831 16.7998 8.25227 17.7591 8.64963C18.7184 9.04699 19.5383 9.7199 20.1152 10.5833C20.6921 11.4466 21 12.4616 21 13.5C21 14.8924 20.4469 16.2277 19.4623 17.2123C18.4777 18.1969 17.1424 18.75 15.75 18.75Z"
                  fill="white"
                />
                <path
                  d="M15.75 9.75C15.0083 9.75 14.2833 9.96993 13.6666 10.382C13.0499 10.794 12.5693 11.3797 12.2855 12.0649C12.0016 12.7502 11.9274 13.5042 12.0721 14.2316C12.2168 14.959 12.5739 15.6272 13.0984 16.1517C13.6228 16.6761 14.291 17.0333 15.0184 17.1779C15.7458 17.3226 16.4998 17.2484 17.1851 16.9646C17.8703 16.6807 18.456 16.2001 18.868 15.5834C19.2801 14.9667 19.5 14.2417 19.5 13.5C19.5 12.5054 19.1049 11.5516 18.4017 10.8483C17.6984 10.1451 16.7446 9.75 15.75 9.75ZM15.75 16.5C15.1567 16.5 14.5766 16.3241 14.0833 15.9944C13.5899 15.6648 13.2054 15.1962 12.9784 14.6481C12.7513 14.0999 12.6919 13.4967 12.8076 12.9147C12.9234 12.3328 13.2091 11.7982 13.6287 11.3787C14.0482 10.9591 14.5828 10.6734 15.1647 10.5576C15.7467 10.4419 16.3499 10.5013 16.8981 10.7284C17.4462 10.9554 17.9148 11.3399 18.2444 11.8333C18.5741 12.3266 18.75 12.9067 18.75 13.5C18.75 14.2957 18.4339 15.0587 17.8713 15.6213C17.3087 16.1839 16.5457 16.5 15.75 16.5Z"
                  fill="white"
                />
                <path
                  d="M9.75 9C10.1642 9 10.5 8.66421 10.5 8.25C10.5 7.83579 10.1642 7.5 9.75 7.5C9.33579 7.5 9 7.83579 9 8.25C9 8.66421 9.33579 9 9.75 9Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="opacity"></div>
          </label>
          </div>
          <div className="profile-nav">
            <NavLink to="/editProfile">Profile</NavLink>
            <NavLink to="/addProducts">Products</NavLink>
            <NavLink to="/payment">Payment</NavLink>
            <a href="" onClick={logOut} className="logOut-btn">Log Out</a>
          </div>
        </div>
    );
}
