import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import aboutImg from "../../assets/images/about.png";

export default function AboutUs() {
  return (
    <div className="aboutUs">
      <Header />
      <Navigation />
      <div className="content sample-page">
        <div className="size">
          <h2 className="title">About us</h2>
          <div className="about">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
              pretium amet, risus malesuada elit viverra ut morbi habitasse.
              Vulputate gravida ut orci purus velit vitae turpis. Neque, platea
              pellentesque nunc tortor sit. Magna sit dignissim gravida neque
              habitant viverra. Massa adipiscing volutpat pellentesque tincidunt
              vitae. Pulvinar morbi tellus ultricies sed tellus, nibh eleifend
              sed. Elit aliquam nulla pellentesque massa diam est.
            </p>
            <div className="about-images">
              <img src={aboutImg} alt="about" />
              <img src={aboutImg} alt="about" />
              <img src={aboutImg} alt="about" />
              <img src={aboutImg} alt="about" />
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
              pretium amet, risus malesuada elit viverra ut morbi habitasse.
              Vulputate gravida ut orci purus velit vitae turpis. Neque, platea
              pellentesque nunc tortor sit. Magna sit dignissim gravida neque
              habitant viverra. Massa adipiscing volutpat pellentesque tincidunt
              vitae. Pulvinar morbi tellus ultricies sed tellus, nibh eleifend
              sed. Elit aliquam nulla pellentesque massa diam est.
            </p>
            <div className="about-images">
              <img src={aboutImg} alt="about" />
              <img src={aboutImg} alt="about" />
              <img src={aboutImg} alt="about" />
              <img src={aboutImg} alt="about" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
