import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import {useState, useEffect,createContext} from "react";
import Pagination from "react-js-pagination";
import SellerCart from "../../components/SellerCart/SellerCart";
import config from "../../../config";

export const ProductContext = createContext();

export default function Sellers() {
    let [data, setData] = useState([]);
    let [activePage, setActivePage] = useState({
        activePage: 1
    });


    useEffect(() => {
        getSellers()
    }, []);


    let getSellers = () => {
        axios
            .get(`${config.url}/getSellers`)
            .then((res) => {
                // console.log(res)
                setData(res.data.users)
            })
            .catch((err) => {
                console.log(err, "error");
            });
    }
    function handlePageChange(pageNumber) {
        // console.log(`active page is ${pageNumber}`);
        setActivePage({activePage: pageNumber});

    }
    let next = activePage.activePage * 20
    // let prev = next - 20;
    let spli = data;
    if (data.length > 20) {
        spli.splice(next, 20);
    }

    return (
        <div className="products-page">
                <Header/>
                <Navigation/>
                <div className="size">
                    <div className="content">
                        <p className="breadcrumbs">All Category / Sellers</p>
                        <div className="products-block">
                            <div className="products">

                                {spli ? spli.map((item, index) => {
                                    return (

                                        <SellerCart
                                            className={"pointer"}
                                            key={index}
                                            name={item.name}
                                            image={item.image}
                                            email={item.email}
                                            id={item.id}

                                        />
                                    );
                                }) : null}
                            </div>
                        </div>

                        <Pagination
                            activePage={activePage.activePage}
                            itemsCountPerPage={20}
                            totalItemsCount={data.length}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            <Footer/>
        </div>
    );
}
