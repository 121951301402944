import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import {useEffect, useState} from "react";
import config from "../../../config";
import axios from "axios";
import validator from "validator";
import { useHistory } from "react-router-dom";


export default function LogIn() {
  const history = useHistory();
  useEffect(() => {
    let getToken = localStorage.getItem("token");
    if(getToken){
      history.push("/editProfile");
    }
  })

  let [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });
  let handleLogin = (e) => {
    setUserLogin({
      ...userLogin,
      [e.target.name]: e.target.value,
    });
  };
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [globalValid, setGlobalValid] = useState('');


  let login = (e) => {
    let email = userLogin.email;
    !validator.isEmail(email) ? setEmailError(false) : setEmailError(true);

    axios
      .post(`${config.url}/login`, userLogin, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {

        console.log(res)
        setEmailError('');
        setPasswordError('');
        setGlobalValid('');
        localStorage.setItem("token", '');

        if (res.data.error){
          if(res.data.error.email && res.data.error.password){
            setEmailError(res.data.error.email)
            setPasswordError(res.data.error.password)
          }else if(res.data.error.password){
            setPasswordError(res.data.error.password)
          }else if(res.data.error.email){
            setEmailError(res.data.error.email)
          }else if(res.data.error){
            setGlobalValid(res.data.error)
          }
        }else if(res.data.success){
          const _token = res.data.success.token;
          localStorage.setItem("token", _token);
          history.push("/editProfile");
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="logIn">
      <Header />
      <Navigation />
      <div className="content">
        <div></div>
        <div className='form'>
          <h2 className="pageTitle">Log in</h2>
          <input
            type="email"
            placeholder="Email"
            onChange={handleLogin}
            name="email" className={emailError && emailError !== true ? 'errorInput' : ''}
          />
          <span
            className="error"
            style={{ display: emailError && emailError !== true ? "block" : "none" }}
          >
            {emailError}
          </span>
          <input
            type="password"
            placeholder="Password"
            onChange={handleLogin}
            name="password"
            className={passwordError && passwordError !== true ? 'errorInput' : ''}
          />
          <span
              className="error"
              style={{ display: passwordError && passwordError !== true ? "block" : "none" }}
          >
            {passwordError}
          </span>
          <span
              className="error"
              style={{ display: globalValid && globalValid !== true ? "block" : "none" }}
          >
            {globalValid}
          </span>

          <NavLink to="/forgotPassword" className="forgot-password">
            Forgot password?
          </NavLink>
          <div className="remember-me">
            <label>
              <input type="checkbox" />
              <span className="checkbox"></span>
              Remember me?
            </label>
          </div>
          <button className="login" onClick={login}>
            LOG IN
          </button>
          <div className="login-link">
            <h5>Don't have an account?</h5>
            <NavLink to="/signUp">Sign Up</NavLink>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
