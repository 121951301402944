import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import config from "../../../config";
import { NavLink } from "react-router-dom";
import ProfileNavigation from "../../components/profileNavigation/ProfileNavigation";
import {useEffect, useState} from "react";
import axios from "axios";

export default function EditProfile() {
  let [editNameState,setEditNameState] = useState(false);
  let [updateMessage,setUpdateMessage] = useState('');
  let [userInfo, setUserInfo] = useState({
    full_name: '',
    about: '',
    phone: '',
    email: '',
    address: '',
    avatar: ''
  });
  let [profileImageState,setProfileImageState] = useState({img : userInfo.avatar})
  useEffect(()=>{
    axios.get(`${config.url}/get-user`,{
      headers: {
        Authorization:
            `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
        'Content-Type': 'application/json'
      }
    }).then((res)=>{
      console.log(res)
      setUserInfo({
        full_name : res.data.name,
        about: res.data.about,
        phone: res.data.phone,
        email: res.data.email,
        address: res.data.address,
        avatar: res.data.image,
      })
      setProfileImageState({
        img:res.data.image
      })

    }).catch((err)=>{
      console.log(err)
    })
  },[])

  function handleUser(e){
    let _val = e.target.value
    if(e.target.name === 'avatar'){
      _val = e.target.files[0]
    }
    setUserInfo({
      ...userInfo,
      [e.target.name] : _val
    })
  }

  function editUser(){
    console.log(userInfo);
    let _formData = new FormData();
    _formData.append('full_name', userInfo.full_name)
    _formData.append('about', userInfo.about)
    _formData.append('phone', userInfo.phone)
    _formData.append('email', userInfo.email)
    _formData.append('address', userInfo.address)
    _formData.append('avatar', userInfo.avatar)

    axios.post(`${config.url}/edit-account`,_formData,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
        'Content-Type': 'application/json'
      }
    }).then((res)=>{
        console.log(res.data)
        setUpdateMessage(res.data.message)
        setUserInfo({
          full_name : res.data.data.name,
          about: res.data.data.about,
          phone: res.data.data.phone,
          email: res.data.data.email,
          address: res.data.data.address,
          avatar: res.data.data.image,
        })
        setProfileImageState({img: res.data.data.image})
    }).catch((err)=>{
        console.log(err)
    })
  }
  let editName = () => {
    setEditNameState(!editNameState);
    document.getElementById("editableName").focus()
  }

  // let handleImage = (e) => {
  //   console.log(e.target.files[0]);
  // }

  return (
    <div className="editProfilePage">
      <Header />
      <Navigation />
      <div className="editProfile profile-content">
      <ProfileNavigation  />

    <div className='edit-profile-block'>
        <div className={"w-100"}>
          <div className="section-title">
            <input className="editableName" type="text" id="editableName" name="full_name" onChange={handleUser} value={userInfo.full_name}  readOnly={editNameState == true ? false : true}/>
            <svg
              onClick={editName}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 17.2501V21.0001H6.75L17.81 9.94006L14.06 6.19006L3 17.2501ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
                fill="#878787"
              />
            </svg>
          </div>
          <div className="followers">
            <div>
              <h6>Followings</h6>
              <p>1.5K </p>
            </div>
            <div>
              <h6>Followers</h6>
              <p>1.5K </p>
            </div>
            <div>
              <h6>Products</h6>
              <p>1.5K </p>
            </div>
          </div>
          <div className="form">
            <label>About me</label>
            <textarea onChange={handleUser} name='about' defaultValue={userInfo.about} ></textarea>
            <label>Phone number</label>
            <input type="tel" onChange={handleUser} name='phone' value={userInfo.phone}/>
            <input type='file' name='avatar' id="avatar_input" onChange={handleUser} />
            <label>Email Address</label>
            <input type="text" onChange={handleUser} name='email' value={userInfo.email} />
            <label>Address</label>
            <input type="text" onChange={handleUser} name='address' value={userInfo.address} />
            <button className="save-details" onClick={editUser}>SAVE</button>
            <div className="message text-center">{updateMessage}</div>
          </div>
        </div>
        <div className="change-password">
          <NavLink to="/">Change password</NavLink>
          <NavLink to="/">Delete account</NavLink>
        </div>
    </div>
      </div>
      <Footer />
    </div>
  );
}
