import "./style.css";

export default function SaleBanner() {
    return (
      <div className="sale-banner">
        <div className='sale-percents'>
          <p>Sale -50</p>
          <p>$58</p>
        </div>
         <h2>Lorem ipsum.</h2>
      </div>
    );
  }