import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import Product from "../../components/product/Product";
import axios from "axios";
import {useState, useEffect,createContext} from "react";
import Pagination from "react-js-pagination";
import Filter from "../../components/filter/Filter"
import {Sort} from "@material-ui/icons";
import SortBy from "../../components/sort/SortBy";

export const ProductContext = createContext();

export default function Products() {
    let [data, setData] = useState([]);
    let [activePage, setActivePage] = useState({
        activePage: 1
    });
    let [categoryData, setCategoryData] = useState();
    let [priceData, setPriceData] = useState();

    useEffect(() => {
        getProducts()
    }, []);



    let getProducts = () => {
        axios
            .get("https://it651.freelancedeveloper.site/api/xml")
            .then((res) => {
                setData(res.data.products.PRODUCTS.ITEM);
                setCategoryData(res.data.categories);
                setPriceData( Number(res.data.price.max))
            })
            .catch((err) => {
                console.log(err, "error");
            });
    }
     let filterDataSend = (data) => {
        setData(data)
    }
    function handlePageChange(pageNumber) {
        // console.log(`active page is ${pageNumber}`);
        setActivePage({activePage: pageNumber});

    }
    let next = activePage.activePage * 20
    // let prev = next - 20;
    let spli = data;
    if (data.length > 20) {
        spli.splice(next, 20);
    }




    return (
        <div className="products-page">
            <ProductContext.Provider value={categoryData}>
                <Header/>
                <Navigation/>
                <div className="size">
                    <div className="content">
                        <p className="breadcrumbs">All Category/ Bracelets & Bangles</p>
                        <h2 className="title">Bracelets & Bangles</h2>
                        {categoryData && priceData ?
                            <Filter category={categoryData} price={priceData} filterDataSend={filterDataSend}/>
                            :
                            null}
                        <div className="products-block">
                            <SortBy/>
                            <div className="products">

                                {spli ? spli.map((item, index) => {
                                    return (
                                        <Product
                                            className={"pointer"}
                                            key={index}
                                            name={item.PRODUCTNAME}
                                            price={item.GUIDEPRICE}
                                            image={item.IMAGE}
                                            code={item.PRODUCTCODE}

                                        />
                                    );
                                }) : null}
                            </div>
                        </div>

                        <Pagination
                            activePage={activePage.activePage}
                            itemsCountPerPage={20}
                            totalItemsCount={data.length}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </ProductContext.Provider>
            <Footer/>
        </div>
    );
}
