import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import Policy from "../../components/policy/Policy";

export default function PrivacyPolicy() {
    return (
      <div className="privacyPolicy">
      <Header />
      <Navigation />
      <div className='content sample-page'>
          <div className='size'>
            <h2 className='title'>Privacy policy</h2>
                <Policy />
                <Policy />
                <Policy />
                <Policy />
                <Policy />
            </div>
      </div>
  
        <Footer />
      </div>
    );
  }
  