import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import ProfileNavigation from "../../components/profileNavigation/ProfileNavigation";
import Product from "../../components/product/Product";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../config";

export default function AddProducts() {

  let [product, setProduct] = useState({
    title: '',
    detail: '',
    desc: '',
    price: '',
    image: ''
  });

  function handleProduct(e){
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  function handleImage(e){
    setProduct({
      ...product,
      image: e.target.files[0]
    })
  }

  function addProduct() {
    let formData = new FormData()
    formData.append('title', product.title);
    formData.append('desc', product.desc);
    formData.append('detail', product.detail);
    formData.append('price', product.price);
    formData.append('image', product.image);
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : ''

    axios.post('https://it651.freelancedeveloper.site/api/product',
      formData, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((res)=>{
      console.log(res)
    }).catch((rej)=>{
      console.log(rej)
    })
  }


    let [data, setData] = useState([]);
    let [path,setPath] = useState("")
    useEffect(() => {
        const token = config.token ? config.token : ''

        axios
            .get("https://it651.freelancedeveloper.site/api/product",{
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((res) => {
                console.log(res)
                setData(res.data.product);
                setPath(res.data.path);
            })
            .catch((err) => {
                console.log(err.response, "dgdflkgjdfklgjdkfgjkfdjg");
            });
    },[]);
  return (
    <div className="addProducts">
      <Header />
      <Navigation />
      <div className="add-products profile-content">
        <ProfileNavigation />

      <div className='add-new-products'>
        <div className="addproducts-block">
          <div className="section-title">
            <h2>Product</h2>
          </div>
            <h4>Add new product</h4>
        </div>
        <div className='form'>
          <div className='upload-img'>
            <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.7495 5.55811C24.9429 5.55811 25.0996 5.40186 25.0996 5.2085V3.57764H26.73C26.9233 3.57764 27.0801 3.42139 27.0801 3.22803C27.0801 3.03467 26.9233 2.87842 26.73 2.87842H25.0996V1.24756C25.0996 1.0542 24.9429 0.897949 24.7495 0.897949C24.5562 0.897949 24.3994 1.0542 24.3994 1.24756V2.87842H22.769C22.5757 2.87842 22.4189 3.03467 22.4189 3.22803C22.4189 3.42139 22.5757 3.57764 22.769 3.57764H24.3994V5.2085C24.3994 5.40186 24.5562 5.55811 24.7495 5.55811Z" fill="#303030"/>
<path d="M12.8413 20.6284C14.9941 20.6284 16.7451 18.8765 16.7451 16.7241C16.7451 14.5718 14.9941 12.8198 12.8413 12.8198C10.6885 12.8198 8.93701 14.5718 8.93701 16.7241C8.93701 18.8765 10.6885 20.6284 12.8413 20.6284ZM12.8413 13.5669C14.5825 13.5669 15.999 14.9829 15.999 16.7241C15.999 18.4653 14.5825 19.8814 12.8413 19.8814C11.1001 19.8814 9.68311 18.4653 9.68311 16.7241C9.68311 14.9829 11.1001 13.5669 12.8413 13.5669Z" fill="#303030"/>
<path d="M17.1074 15.0122C17.321 15.5424 17.4453 16.1182 17.4453 16.7241C17.4453 19.2632 15.3799 21.3276 12.8413 21.3276C10.3022 21.3276 8.23682 19.2632 8.23682 16.7241C8.23682 16.1182 8.36121 15.5424 8.57483 15.0122H1.62061H0.92041V24.0835C0.92041 24.645 1.37793 25.102 1.94043 25.102H23.731C24.2993 25.102 24.7617 24.6401 24.7617 24.0718V15.0122H24.0615H17.1074Z" fill="#303030"/>
<path d="M23.7417 8.33838H17.8745L16.625 6.12158C16.4502 5.80908 16.1191 5.61572 15.7617 5.61572H9.92041C9.56299 5.61572 9.23193 5.80908 9.05762 6.1206L7.80713 8.33837H1.92969C1.37305 8.33837 0.92041 8.79052 0.92041 9.34716V14.313H1.62061H8.93659C9.74909 13.0026 11.189 12.1206 12.8413 12.1206C14.4934 12.1206 15.9331 13.0026 16.7456 14.313H24.0615H24.7617V9.3579C24.7617 8.79541 24.3042 8.33838 23.7417 8.33838Z" fill="#303030"/>
</svg>
            <p className='upload-photo'>Upload photo</p>
            <input type='file' name='image' onChange={handleImage} />
            <p>Or Drop photo here</p>
          </div>
          <div className='product-details'>
            <div>
              <div>
                <input type='text' placeholder='Title *' name="title" onChange={handleProduct} />
                <textarea placeholder='Description *' onChange={handleProduct} name='desc'></textarea>
              </div>
              <div>
                <textarea placeholder='Details *' onChange={handleProduct} name='detail'></textarea>
                <input type='text' placeholder='Price *' onChange={handleProduct} name='price'/>
              </div>
            </div>
            <button className='save-details' onClick={addProduct}>Publish</button>
          </div>
        </div>
        <div className='my-products'>
          <h4>My products</h4>
          <div className="products">
              {data.map((item, index) => {
                  return (
                      <Product
                          key={index}
                          name={item.title}
                          price={item.price}
                          image={path+"/"+item.image}
                          code={item.id}
                      />

                  );
              })}
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
