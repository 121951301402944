import plusFilterImg from "../../assets/images/Plus.svg";
import minusFilterImg from "../../assets/images/Minus.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider"
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../config";

export default function FilterItem(props) {
    const [togglerFilterBy, setTogglerFilterBy] = useState(false);

    let togglerFilterByHandler = (props) => {
        setTogglerFilterBy(!togglerFilterBy);
    }

    function valuetext(value) {
        return `$${value}`;
    }


    const [categoryData, setCategoryData] = useState([]);
    const [categoryData2, setCategoryData2] = useState([]);
    const [categoryData3, setCategoryData3] = useState([]);
    useEffect(() => {

        let arr = [];
        let arr2 = [];
        let arr3 = [];

        if (props.category) {
            for (const [key, value] of Object.entries(props.category)) {
                arr.push(key)
                let val = value;
                let k = key;
                for (const [key, value] of Object.entries(props.category[k])) {
                    arr2.push({[k]: key});

                    let key2 = key;
                }
            }
        }
        setCategoryData(arr)
        setCategoryData2(arr2)
        setCategoryData3(arr3)

    }, [])
    const [firstLevel, setFirstLevel] = useState(false);
    const [secondLevel, setSecondLevel] = useState(false);
    const [firstLevelTag, setFirstLevelTag] = useState("");
    const [secondLevelTag, setSecondLevelTag] = useState("");

    const[filteredProducts,setFilteredProducts] = useState();

    let openFirstCategoryLevel = (x) => {
        setFirstLevel(!firstLevel);
        setSecondLevel(false);
        // if (firstLevel === false) {
            axios
                .get(`${config.url}/filterByCategory/${x}/${props.price[0]}/${props.price[1]}`, {
                    headers: {
                        Authorization:
                            `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    // console.log(res)
                    props.filterDataSend(res.data);
                    setFilteredProducts(res.data);


                })
                .catch((err) => {
                    console.log(err);
                });
        // }
    }

    let openSecondCategoryLevel = (data) => {
        setSecondLevel(!secondLevel);
        setFirstLevelTag(Object.keys(data)[0])
        setSecondLevelTag(Object.values(data)[0])
        // if (secondLevel === false) {

            axios
                .get(`${config.url}/filterByCategory/${Object.keys(data)[0]}/${Object.values(data)[0]}/${props.price[0]}/${props.price[1]}`, {
                    headers: {
                        Authorization:
                            `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    // console.log(res)
                    props.filterDataSend(res.data);
                    setFilteredProducts(res.data);

                })
                .catch((err) => {
                    console.log(err);
                });
        // }
    }
    let openThirdCategoryLevel = (data) => {

    }
    return (
        <div className="filter-by">
            <div className="filter-by-head d-flex align-center justify-between" onClick={togglerFilterByHandler}>
                {props.name}<img src={togglerFilterBy === false ? plusFilterImg : minusFilterImg} alt="plusFilterImg"/>
            </div>

            {togglerFilterBy === true ?
                <div className="filter-by-body">
                    {(() => {

                        switch (props.name) {
                            case 'Category':
                                return (

                                    categoryData.map((itemKeys, index) => {
                                        return (
                                            <div className={"category-body-items"} key={index}>
                                                <div>
                                                    <div className={"firstLevel level"} onClick={() => {
                                                        openFirstCategoryLevel(itemKeys)
                                                    }}>{itemKeys}</div>
                                                    {
                                                        categoryData2.map((item, index) => {
                                                            let itemName = item[itemKeys];
                                                            return (
                                                                itemName !== undefined ?
                                                                    <div key={index}>
                                                                        <div key={index} onClick={() => {
                                                                            openSecondCategoryLevel(item)
                                                                        }}
                                                                             className={firstLevel ? "secondLevel level" : "d-none"}
                                                                             style={{marginLeft: "30px"}}>{item[itemKeys]}</div>

                                                                        {secondLevel && secondLevelTag == item[itemKeys] && itemKeys == firstLevelTag ?
                                                                            <div style={{marginLeft: "60px"}}
                                                                                 className={"thirdLevel level"}
                                                                                 onClick={() => {
                                                                                     openThirdCategoryLevel(props.category[itemKeys][item[itemKeys]])
                                                                                 }}>{props.category[itemKeys][item[itemKeys]] ? props.category[itemKeys][item[itemKeys]].child : null}</div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                            )
                                                        })
                                                    }


                                                </div>
                                            </div>
                                        )
                                    })

                                )

                            case 'Price':
                                return (
                                    <div className="price-body ">
                                        <div style={{padding: "0 20px"}}>
                                            <Slider
                                                value={props.price}
                                                onChange={props.handleChange}
                                                step={100}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                                getAriaValueText={valuetext}
                                                min={0}
                                                max={10000}
                                            />
                                        </div>
                                    </div>

                                )
                            default:
                                return (
                                    <div>Filter Empty</div>
                                )
                        }

                    })()}


                </div>


                : null}

        </div>
    )
}
