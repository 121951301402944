import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import {useState} from "react";
import axios from "axios";
import config from "../../../config";

export default function Contact() {

    let [contact, setContact] = useState({
        name: '',
        email: '',
        reason: '',
        message: ''
    })
    let [contactValid, setContactValid] = useState({

    })
    let [successValid,setSuccessValid] = useState("")

    function handleContact(e) {
        setContact({
            ...contact,
            [e.target.name]: e.target.value
        })
    }

    function send() {
        axios.post(`${config.url}/contacts`, contact)
            .then((res) => {
                console.log(res )
                setContactValid("")
                setSuccessValid("")
                if(res.data.success){
                    setSuccessValid(res.data.success.message)
                }
                if(res.data.error){
                    setContactValid(res.data.error.message)
                }


            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="contact">
            <Header/>
            <Navigation/>
            <div className="content sample-page">
                <div className="size">
                    <h2 className="title">Contact us</h2>
                    <div className="contact-form">
                        <div className={"w-100 mg-b-20"}>
                            <input name="name" placeholder="Name" onChange={handleContact} className={contactValid.name && contactValid.name !== true ? 'errorInput' : ''}/>
                            <span
                                className="error"
                                style={{display: contactValid.name && contactValid.name !== true ? "block" : "none"}}
                            >
                                {contactValid.name}
                                </span>
                        </div>
                        <div className={"w-100 mg-b-20"}>
                            <input name="email" placeholder="Email" onChange={handleContact} className={contactValid.email && contactValid.email !== true ? 'errorInput' : ''}/>
                            <span
                                className="error"
                                style={{display: contactValid.email && contactValid.email !== true ? "block" : "none"}}
                            >
                                {contactValid.email}
                                </span>
                        </div>
                        <div className='contact-reason  mg-b-20 '>
                            <select placeholder="reason" name='reason' defaultValue={"reason"} onChange={handleContact} className={contactValid.reason && contactValid.reason !== true ? 'errorInput' : ''}>
                                <option value ={"reason"} disabled >Reason</option>
                                <option value ={'1'}>1</option>
                                <option value ={'2'}>2</option>
                                <option value ={'3'}>3</option>
                                <option value ={'4'}>4</option>
                                <option value ={'5'}>5</option>
                            </select>
                            <span
                                className="error"
                                style={{display: contactValid.reason && contactValid.reason !== true ? "block" : "none"}}
                            >
                                {contactValid.reason}
                                </span>
                        </div>
                        <div  className={"w-100  mg-b-20"}>
                            <textarea placeholder="Text your comment here" name='message'  className={contactValid.message && contactValid.message !== true ? 'errorInput' : ''}
                                  onChange={handleContact}></textarea>
                            <span
                                className="error"
                                style={{display: contactValid.message && contactValid.message !== true ? "block" : "none"}}
                            >
                                {contactValid.message}
                                </span>
                        </div>
                        <button className="btn" onClick={() => {send()}}>Send</button>
                        <span
                            className="success"
                            style={{display: successValid && successValid !== true ? "block" : "none"}}
                        >
                                {successValid}
                                </span>
                    </div>
                    <div className="contact-info">
                        <h6>Contact info</h6>
                        <div>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M23.3702 18.5144L18.5125 15.2748C17.8961 14.867 17.0697 15.0012 16.614 15.5832L15.199 17.4025C15.0172 17.6423 14.6866 17.7118 14.4235 17.5657L14.1544 17.4173C13.2621 16.931 12.1519 16.3255 9.91501 14.0878C7.67814 11.8501 7.07144 10.7391 6.58509 9.84845L6.4375 9.57929C6.28935 9.31627 6.35759 8.9843 6.59746 8.801L8.41556 7.38643C8.99729 6.93063 9.13166 6.10454 8.72433 5.4879L5.48481 0.63021C5.06736 0.00227204 4.22983 -0.186231 3.58356 0.202258L1.55225 1.42247C0.914006 1.79769 0.445747 2.40538 0.245667 3.1182C-0.485802 5.7834 0.064475 10.3831 6.84028 17.1597C12.2303 22.5493 16.2429 23.9987 19.0009 23.9987C19.6356 24.0014 20.2679 23.9188 20.8806 23.7532C21.5936 23.5533 22.2014 23.085 22.5764 22.4466L23.7978 20.4165C24.1868 19.7701 23.9984 18.932 23.3702 18.5144ZM23.1087 20.0061L21.89 22.0374C21.6208 22.4982 21.1836 22.837 20.6702 22.9829C18.2098 23.6584 13.9112 23.0989 7.40617 16.5938C0.901117 10.0888 0.341653 5.79062 1.01716 3.32981C1.1632 2.81576 1.50243 2.37806 1.9638 2.10838L3.99511 0.889765C4.27552 0.72104 4.63903 0.802825 4.82018 1.07532L6.57993 3.71731L8.05693 5.93258C8.23381 6.20006 8.17569 6.55855 7.92336 6.75647L6.10483 8.17104C5.55141 8.59422 5.39337 9.3599 5.7341 9.96759L5.8785 10.23C6.38965 11.1674 7.02513 12.3336 9.34519 14.6533C11.6653 16.973 12.8311 17.6085 13.7681 18.1196L14.0309 18.2644C14.6385 18.605 15.4042 18.4471 15.8274 17.8936L17.242 16.0751C17.44 15.8229 17.7983 15.7648 18.0659 15.9415L22.9231 19.1811C23.1958 19.3621 23.2776 19.7258 23.1087 20.0061Z"
                                    fill="black"
                                />
                                <path
                                    d="M13.5991 4.00151C17.3524 4.00568 20.3939 7.04728 20.3982 10.8005C20.3982 11.0214 20.5772 11.2004 20.7981 11.2004C21.019 11.2004 21.198 11.0214 21.198 10.8005C21.1934 6.60569 17.794 3.20625 13.5991 3.20166C13.3783 3.20166 13.1992 3.3807 13.1992 3.60159C13.1992 3.82247 13.3783 4.00151 13.5991 4.00151Z"
                                    fill="black"
                                />
                                <path
                                    d="M13.5991 6.40141C16.0277 6.40427 17.9956 8.37226 17.9985 10.8008C17.9985 11.0217 18.1776 11.2007 18.3984 11.2007C18.6193 11.2007 18.7984 11.0217 18.7984 10.8008C18.795 7.93067 16.4692 5.60484 13.5991 5.60156C13.3783 5.60156 13.1992 5.7806 13.1992 6.00149C13.1992 6.22238 13.3783 6.40141 13.5991 6.40141Z"
                                    fill="black"
                                />
                                <path
                                    d="M13.5991 8.80083C14.703 8.80214 15.5975 9.69666 15.5989 10.8005C15.5989 11.0214 15.7779 11.2005 15.9988 11.2005C16.2196 11.2005 16.3987 11.0214 16.3987 10.8005C16.3969 9.25512 15.1446 8.00271 13.5991 8.00098C13.3783 8.00098 13.1992 8.18001 13.1992 8.4009C13.1992 8.62179 13.3783 8.80083 13.5991 8.80083Z"
                                    fill="black"
                                />
                            </svg>
                            <h6>+123 456 789</h6>
                        </div>
                        <div>
                            <svg
                                width="24"
                                height="20"
                                viewBox="0 0 24 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M22.8 0.800293H1.2C0.537281 0.800293 0 1.33753 0 2.00029V18.0003C0 18.663 0.537234 19.2003 1.2 19.2003H22.8C23.4627 19.2003 24 18.663 24 18.0003V2.00029C24 1.33757 23.4628 0.800293 22.8 0.800293ZM23.2 18.0003C23.2 18.2212 23.0209 18.4003 22.8 18.4003H1.2C0.979078 18.4003 0.800016 18.2212 0.800016 18.0003V2.00029C0.800016 1.77937 0.979078 1.60031 1.2 1.60031H22.8C23.0209 1.60031 23.2 1.77937 23.2 2.00029V18.0003Z"
                                    fill="black"
                                />
                                <path
                                    d="M22.0344 2.40194C21.9287 2.3927 21.8237 2.42584 21.7425 2.49409L12.5144 10.2461C12.2168 10.4962 11.7824 10.4962 11.4848 10.2461L2.25685 2.49404C2.1474 2.40217 1.99721 2.37564 1.86291 2.42448C1.72862 2.47333 1.63055 2.59014 1.60571 2.7309C1.58087 2.87167 1.63299 3.01497 1.74244 3.10684L10.9704 10.8584C11.5652 11.3592 12.4341 11.3592 13.0289 10.8584L22.2568 3.10689C22.3381 3.03869 22.3889 2.941 22.3981 2.83529C22.4074 2.72959 22.3742 2.62454 22.306 2.54331C22.2378 2.46198 22.1401 2.41117 22.0344 2.40194Z"
                                    fill="black"
                                />
                                <path
                                    d="M7.69331 10.8031C7.5517 10.7715 7.40414 10.819 7.30758 10.9273L1.70756 16.9273C1.60622 17.0311 1.569 17.1816 1.61034 17.3207C1.65168 17.4597 1.76508 17.5655 1.90664 17.5971C2.04825 17.6287 2.19581 17.5811 2.29237 17.4729L7.89239 11.4729C7.99373 11.3691 8.03095 11.2185 7.98961 11.0795C7.94826 10.9405 7.83492 10.8347 7.69331 10.8031Z"
                                    fill="black"
                                />
                                <path
                                    d="M16.6928 10.9273C16.5962 10.819 16.4486 10.7715 16.307 10.8031C16.1654 10.8347 16.0521 10.9405 16.0107 11.0795C15.9694 11.2186 16.0066 11.3691 16.108 11.4729L21.708 17.4729C21.8598 17.6284 22.1081 17.6342 22.267 17.4859C22.426 17.3376 22.4374 17.0895 22.2928 16.9272L16.6928 10.9273Z"
                                    fill="black"
                                />
                            </svg>
                            <h6>Yourgmail@gmail.com</h6>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
