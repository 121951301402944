import './style.css';
import Product from "../product/Product";

export default function TopProduct() {
    return (
        <div className='top-products'>
            <div className='size'>

            <div className='top-products-title'>
                <p>Top products</p>
                <h3>Trending this week</h3>
            </div>

                <div className='products'>

                    <Product />
                    <Product />
                    <Product />
                    <Product />
                    <Product />

                </div>
            </div>
        </div>
    )
}
