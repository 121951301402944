import "./style.css";
import {useContext, useEffect, useState} from "react";
import closeFilterImg from "../../assets/images/x.svg";
import FilterItem from "../filter-item/Filter-item";
import {ProductContext} from "../../containers/products/Products";
import axios from "axios";
import config from "../../../config";

export default function Filter(props) {
    const x = useContext(ProductContext);
    const [togglerFilter, setTogglerFilter] = useState(false);
    const [filter, setFilter] = useState([
        {
            name: 'Category',
            category: props.category

        },
        {
            name: 'Price',
            price: props.price
        },
    ]);
    const [price, setPrice] = useState([1, 10000]);

    useEffect(() => {
        const handleTimeout =  setTimeout(function () {
            axios
                .get(`${config.url}/filterPrice/${price[0]}/${price[1]}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res);
                    props.filterDataSend(res.data)

                })
                .catch((err) => {
                    console.log(err);
                });
        },1000)
        return () => {
            clearTimeout(handleTimeout)
        }
    }, [price])

    const handleChange = (event, newValue) => {
        setPrice(newValue);

    };

    let openFilterHandler = () => {
        setTogglerFilter(!togglerFilter);
    }
    return (
        <div className="filter">
            <div className="filter-toggle-img" onClick={openFilterHandler}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.7768 6.21428H2.57101C2.09763 6.21428 1.71387 5.83052 1.71387 5.35714C1.71387 4.88376 2.09763 4.5 2.57101 4.5H3.7768C4.13832 3.38089 5.18871 2.57143 6.42815 2.57143C7.6676 2.57143 8.71801 3.38089 9.07955 4.5H21.4282C21.9016 4.5 22.2853 4.88376 22.2853 5.35714C22.2853 5.83052 21.9016 6.21428 21.4282 6.21428H9.07955C8.71801 7.33339 7.6676 8.14285 6.42815 8.14285C5.18871 8.14285 4.13832 7.33339 3.7768 6.21428ZM4.92815 5.35714C4.92815 4.52871 5.59972 3.85714 6.42815 3.85714C7.25658 3.85714 7.92815 4.52871 7.92815 5.35714C7.92815 6.18557 7.25658 6.85714 6.42815 6.85714C5.59972 6.85714 4.92815 6.18557 4.92815 5.35714Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.57101 13.0714H14.9531C15.3147 14.1905 16.365 15 17.6045 15C18.844 15 19.8943 14.1905 20.2558 13.0714H21.4282C21.9016 13.0714 22.2853 12.6877 22.2853 12.2143C22.2853 11.7409 21.9016 11.3571 21.4282 11.3571H20.2558C19.8943 10.2381 18.844 9.42857 17.6045 9.42857C16.365 9.42857 15.3147 10.2381 14.9531 11.3571H2.57101C2.09763 11.3571 1.71387 11.7409 1.71387 12.2143C1.71387 12.6877 2.09763 13.0714 2.57101 13.0714ZM17.6045 10.7143C16.7761 10.7143 16.1045 11.3859 16.1045 12.2143C16.1045 13.0427 16.7761 13.7143 17.6045 13.7143C18.433 13.7143 19.1045 13.0427 19.1045 12.2143C19.1045 11.3859 18.433 10.7143 17.6045 10.7143Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.91961 19.9286H2.57101C2.09763 19.9286 1.71387 19.5448 1.71387 19.0714C1.71387 18.598 2.09763 18.2143 2.57101 18.2143H8.91961C9.28115 17.0952 10.3316 16.2857 11.571 16.2857C12.8104 16.2857 13.8609 17.0952 14.2224 18.2143H21.4282C21.9016 18.2143 22.2853 18.598 22.2853 19.0714C22.2853 19.5448 21.9016 19.9286 21.4282 19.9286H14.2224C13.8609 21.0476 12.8104 21.8571 11.571 21.8571C10.3316 21.8571 9.28115 21.0476 8.91961 19.9286ZM10.071 19.0714C10.071 18.243 10.7426 17.5714 11.571 17.5714C12.3994 17.5714 13.071 18.243 13.071 19.0714C13.071 19.8998 12.3994 20.5714 11.571 20.5714C10.7426 20.5714 10.071 19.8998 10.071 19.0714Z"
                        fill="black"
                    />
                </svg>
            </div>
            {
                togglerFilter === true ?
                    <div className="filter-body">
                        <div className="closeFilter justify-end d-flex align-center" onClick={openFilterHandler}>
                            Close filters <img src={closeFilterImg} style={{marginLeft: "28px"}} alt="closeFilterImg"/>
                        </div>
                        <div className="title">
                            Filter By
                        </div>
                        <div className="filters">
                            {filter.map((item, index) => {
                                return (
                                    <FilterItem name={item.name} index={index} category={item.category} price={price}
                                                key={index} filterDataSend={props.filterDataSend} handleChange={handleChange} />
                                )
                            })}
                        </div>
                    </div>
                    : null
            }

        </div>
    )
}

