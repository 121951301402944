import "./style.css";

export default function Policy() {
  return (
    <div className="policy">
      <div className="content">
        <h4 className='policy-title'>Lorem ipsum dolor</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id sed
          quam fringilla nisl mauris bibendum accumsan. Dignissim odio sed amet
          in libero. Nibh convallis lectus at sapien amet eu. Magnis
          pellentesque leo neque et purus odio convallis gravida. Blandit in
          fringilla facilisi enim quam etiam. Fames morbi mattis potenti eget a,
          ipsum massa. Convallis pretium ultrices convallis non. Diam, sit
          commodo congue tincidunt lorem tempor gravida elit id. Nunc vel
          ultrices vehicula nisl malesuada id rhoncus sapien libero. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Proin id sed quam
          fringilla nisl mauris bibendum accumsan. Dignissim odio sed amet in
          libero. Nibh convallis lectus at sapien amet eu. Magnis pellentesque
          leo neque et purus odio convallis gravida. Blandit in fringilla
          facilisi enim quam etiam. Fames morbi mattis potenti eget a, ipsum
          massa. Convallis pretium ultrices convallis non. Diam, sit commodo
          congue tincidunt lorem tempor gravida elit id. Nunc vel ultrices
          vehicula nisl malesuada id rhoncus sapien libero.Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Proin id sed quam fringilla nisl
          mauris bibendum accumsan. Dignissim odio sed amet in libero. Nibh
          convallis lectus at sapien amet eu. Magnis pellentesque leo neque et
          purus odio convallis gravida. Blandit in fringilla facilisi enim quam
          etiam. Fames morbi mattis potenti eget a, ipsum massa. Convallis
          pretium ultrices convallis non. Diam, sit commodo congue tincidunt
          lorem tempor gravida elit id. Nunc vel ultrices vehicula nisl
          malesuada id rhoncus sapien libero.Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Proin id sed quam fringilla nisl mauris
          bibendum accumsan. Dignissim odio sed amet in libero. Nibh convallis
          lectus at sapien amet eu. Magnis pellentesque leo neque et purus odio
          convallis gravida. Blandit in fringilla facilisi enim quam etiam.
          Fames morbi mattis potenti eget a, ipsum massa. Convallis pretium
          ultrices convallis non. Diam, sit commodo congue tincidunt lorem
          tempor gravida elit id. Nunc vel ultrices vehicula nisl malesuada id
          rhoncus sapien libero.
        </p>
      </div>
    </div>
  );
}
