import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../config"
import ReactCodeInput from "react-code-input";
import { useHistory } from "react-router-dom";


export default function Verification() {
    const history = useHistory();
    let getVerificationEmail = localStorage.getItem("verificationEmail");
    let [verifyCode, setVerifyCode] = useState("");
    const [codeError, setCodeError] = useState();

    let handleCodeInput = (e) => {
        setVerifyCode(e)
    }


    let verificationFormSend = () => {
        axios.post(`${config.url}/verify`, {email:getVerificationEmail,code:verifyCode}, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {

                console.log(res)
                if (res.data.error){
                    if(res.data.error.code){
                        setCodeError(res.data.error.code);
                    }
                }else{
                    history.push("/newPassword");
                }

            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className="verification">
            <Header/>
            <Navigation/>
            <div className='content'>
                <div></div>
                <div className='form'>
                    <h2 className='pageTitle'>Enter the code</h2>
                    <p className='notice'>We sent you a verification code via email.</p>
                    <div className='code'>
                        <ReactCodeInput type='number' fields={4} onChange={handleCodeInput} />
                    </div>
                    <span
                        className="error text-center"
                        style={{ display: codeError && codeError !== true ? "block" : "none" }}
                    >
                        {codeError}
                      </span>
                    <button className='submit' onClick={verificationFormSend}>SUBMIT</button>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
