import "./style.css";
import Product from "../product/Product";
import SaleBanner from "../SaleBanner/SaleBanner";

export default function Sale() {
  return (
    <div className="sale-products">
        <div className='size'>
          <div className='content'>
            <div className='products'>
              <Product />
              <Product />
              <Product />
              <Product />
              <Product />
              <Product />
            </div>
            <div className="sale-ad">
              <SaleBanner />
            </div>
          </div>
        </div>
    </div>
  );
}