import "./style.css";
import {useState} from "react";
import closeFilterImg from "../../assets/images/x.svg";
import FilterItem from "../filter-item/Filter-item";
import RadioButton from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from "@material-ui/core/FormControlLabel";


export default function SortBy() {
    const [togglerFilter, setTogglerFilter] = useState(false);

    let openFilterHandler = () => {
        setTogglerFilter(!togglerFilter);
    }



    return (
        <div className="sort-by ">
            <div onClick={openFilterHandler}>
                <p>Sort by</p>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.01172 4.48921V19.3727"
                        stroke="#0D0F0F"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M5.81836 16.2647L8.01117 19.2"
                        stroke="#0D0F0F"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M8.01172 19.2L10.2045 16.2647"
                        stroke="#0D0F0F"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M8.42546 19.269H7.59668V19.5108H8.42546V19.269Z"
                        fill="#0D0F0F"
                    />
                    <path
                        d="M16.0059 4.55827V19.4417"
                        stroke="#0D0F0F"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M16.0053 4.73094L13.8125 7.6662"
                        stroke="#0D0F0F"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M18.1987 7.6662L16.0059 4.73094"
                        stroke="#0D0F0F"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M16.4206 4.42014H15.5918V4.66186H16.4206V4.42014Z"
                        fill="#0D0F0F"
                    />
                </svg>
            </div>
            {
                togglerFilter === true ?
                    <div className="sort-body">
                        <div className="closeSort justify-end d-flex align-center" onClick={openFilterHandler}>
                            <img src={closeFilterImg} alt="closeFilterImg"/>
                        </div>
                        <div className="title">
                            Sort by
                        </div>
                        <div className="Sorts">
                            <RadioGroup defaultValue="Date" aria-label="date" name="customized-radios">

                            <FormControlLabel
                                control={
                                    <RadioButton
                                        value={'Date'}
                                        inputProps={{'aria-label': 'Date'}}
                                        color="primary"
                                        // checked={data.checked}
                                    />
                                }
                                label={"Date"}
                            />
                            <FormControlLabel
                                control={
                                    <RadioButton
                                        value={'Price'}
                                        inputProps={{'aria-label': 'Price'}}
                                        color="primary"
                                        // checked={data.checked}
                                    />
                                }
                                label={"Price"}
                            />
                            <FormControlLabel
                                control={
                                    <RadioButton
                                        value={'Name'}
                                        inputProps={{'aria-label': 'Name'}}
                                        color="primary"
                                        // checked={data.checked}
                                    />
                                }
                                label={"Name"}
                            />
                            </RadioGroup>

                        </div>
                    </div>
                    : null
            }

        </div>

    )
}

