import "./style.css";
import buyIcon from "../../assets/images/Vector.png";
import { NavLink } from "react-router-dom";

export default function Banner() {
  return (
    <div className="banner">
      <div className="size">
        <div className="content">
          <h1>Lorem ipsum dolor sit amet, consetetur.</h1>
          <h4>
            A transformative triple‑camera system that adds tons of capability
            without complexity
          </h4>

          <div className="buy-now">
            <NavLink to="/">
              <span>Buy Now</span>
              <img src={buyIcon} alt='buy'/>
            </NavLink>

            <NavLink to="/">With $599 with trade-in</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
