import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../config";
import validator from "validator";
import { useHistory } from "react-router-dom";


export default function SignUp() {
  const history = useHistory();

  useEffect(() => {
    let getToken = localStorage.getItem("token");
    if(getToken){
      history.push("/editProfile");
    }
  })


  let [register, setRegister] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [globalValid, setGlobalValid] = useState('');

  function handleSignUp(e) {
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
    });
  }

  function signUpUser(e) {
    const email = register.email;

    !validator.isEmail(email) ? setEmailError(false) : setEmailError(true);

    axios
      .post(`${config.url}/register`, register)
      .then((res) => {
        console.log(res)
        setEmailError('');
        setPasswordError('');
        setGlobalValid('');
        localStorage.setItem("token", '');

        if (res.data.error){
          if(res.data.error.email && res.data.error.password){
            setEmailError(res.data.error.email)
            setPasswordError(res.data.error.password)
          }else if(res.data.error.password){
            setPasswordError(res.data.error.password)
          }else if(res.data.error.email){
            setEmailError(res.data.error.email)
          }else if(res.data.error){
            setGlobalValid(res.data.error)
          }
        }else if(res.data.success){
          const _token = res.data.success.token;
          localStorage.setItem("token", _token);
          history.push("/editProfile");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="signUp">
      <Header />
      <Navigation />
      <div className="content">
        <div></div>
        <div className="form">
          <h2 className="pageTitle">Sign up</h2>
          <input
              type="email"
              placeholder="Email"
              onChange={handleSignUp}
              name="email" className={emailError && emailError !== true ? 'errorInput' : ''}
          />
          <span
              className="error"
              style={{ display: emailError && emailError !== true ? "block" : "none" }}
          >
            {emailError}
          </span>
          <input
              type="password"
              placeholder="Password"
              onChange={handleSignUp}
              name="password"
              className={passwordError && passwordError !== true ? 'errorInput' : ''}
          />
          <span
              className="error"
              style={{ display: passwordError && passwordError !== true ? "block" : "none" }}
          >
            {passwordError}
          </span>

          <input
            type="password"
            placeholder="Repeat password"
            name="password_confirmation"
            onChange={handleSignUp}
          />
          <div className="agreement">
            <label>
              <input type="radio" />
              <span className="radioBtn"></span>I agree to terms & conditions
            </label>
          </div>
          <button className="signup" onClick={signUpUser}>
            SIGN UP
          </button>
          <div className="login-link">
            <h5>Have an account?</h5>
            <NavLink to="/logIn">Log in</NavLink>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
