import "./style.css";
import Product from "../../components/product/Product";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import sellerImg from "../../assets/images/seller.png";
import axios from "axios";
import config from "../../../config";
import {useEffect, useState} from "react";

export default function SellerCart(props) {
  const [data,setData] = useState();
  let sellerId = localStorage.getItem("SellerId");
  useEffect(() => {
    axios
        .get(`${config.url}/getSellersById/${sellerId}`, {
          headers: {
            Authorization:
                `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          setData(res.data);
        })
        .catch((err) => {
          console.log(err, "error");
        });
  },[])

  return (
    <div className="seller">
      <Header />
      <Navigation />
      <div className="size">
        <p className="breadcrumbs">All Category/Sellers/{data.user.name}</p>
        <p className='last-seen'>Last seen: Today  12:35</p>
        <div className="content seller-profile-content">
          <div className="seller-img">
            <img src={data.user.image} alt="seller" />
          </div>
          <div className="seller-info">
            <div className="section-title">
              <h2>{data.user.name}</h2>
            </div>
            <div className="followers">
              <div>
                <h6>Followings</h6>
                <p>1.5K </p>
              </div>
              <div>
                <h6>Followers</h6>
                <p>1.5K </p>
              </div>
              <div>
                <h6>Products</h6>
                <p>1.5K </p>
              </div>
            </div>
            <div className="about-seller">
              <h4>About seller</h4>
              <h6>
                {data.user.about}
              </h6>
            </div>
            <div className="seller-contact-info">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0C7.58889 0 4 3.58889 4 8.00002C4 9.32423 4.33108 10.6372 4.96047 11.8018L11.5625 23.7422C11.6504 23.9014 11.8179 24 12 24C12.1821 24 12.3496 23.9014 12.4375 23.7422L19.042 11.7978C19.669 10.6372 20 9.32419 20 7.99997C20 3.58889 16.4111 0 12 0ZM18.1645 11.3179L12 22.4668L5.83792 11.3223C5.28958 10.3076 5.00003 9.15872 5.00003 8.00002C5.00003 4.14014 8.14019 1.00003 12 1.00003C15.8598 1.00003 19 4.14019 19 8.00002C19 9.15867 18.7105 10.3076 18.1645 11.3179Z"
                    fill="black"
                  />
                  <path
                    d="M12 4C9.79442 4 8 5.79442 8 7.99998C8 10.2056 9.79442 12 12 12C14.2055 12 16 10.2056 16 8.00003C16 5.79447 14.2055 4 12 4ZM12 11C10.3457 11 8.99998 9.65434 8.99998 8.00003C8.99998 6.34572 10.3457 5.00003 12 5.00003C13.6543 5.00003 15 6.34572 15 8.00003C15 9.6543 13.6543 11 12 11Z"
                    fill="black"
                  />
                </svg>
                <h6>{data.user.address}</h6>
              </div>
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M14.5044 16.9211C14.5044 16.9211 16.4479 15.8084 16.9567 15.5411C17.4641 15.2727 17.9897 15.2051 18.3089 15.4C18.7922 15.6956 22.8511 18.3956 23.1929 18.6347C23.5346 18.8742 23.6993 19.5582 23.2293 20.2283C22.7613 20.8984 20.6023 23.548 19.6874 23.5196C18.7711 23.4899 14.9613 23.4064 7.77718 16.2203C0.594461 9.03659 0.509501 5.22587 0.480221 4.30955C0.450941 3.39371 3.10054 1.23419 3.77062 0.76571C4.44166 0.29771 5.12662 0.47387 5.3647 0.80315C5.63542 1.17803 8.30422 5.22395 8.59798 5.68763C8.7991 6.00443 8.72518 6.53291 8.45686 7.04075C8.18998 7.54955 7.07734 9.49307 7.07734 9.49307C7.07734 9.49307 7.86166 10.8308 10.5141 13.4828C13.1671 16.1353 14.5044 16.9211 14.5044 16.9211Z"
                      stroke="black"
                      strokeMiterlimit="10"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <a href="tel:+123 456 789">{data.user.phone}</a>
              </div>
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M23.52 3.84009H0.47998V20.1601H23.52V3.84009Z"
                      stroke="black"
                      strokeMiterlimit="10"
                      strokel="true"
                      inecap="round"
                    />
                    <path
                      d="M16.0112 12.3176L23.4671 18.2125"
                      stroke="black"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M0.532715 18.2122L8.31159 12.061"
                      stroke="black"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M0.532715 5.7854C0.532715 5.7854 9.36472 13.4832 10.0842 14.1096C10.8038 14.736 11.553 14.88 11.9999 14.88C12.4468 14.88 13.1961 14.7364 13.9156 14.11C14.6351 13.4836 23.4671 5.78588 23.4671 5.78588"
                      stroke="black"
                      strokeMiterlimit="10"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <a href="mailto:sellermail@gmail.com">{data.user.email}</a>
              </div>
            </div>
            <div className="seller-actions">
              <button className="btn">FOLLOW</button>
              <button className="send-message">Send message</button>
              <button className="more">
                <svg
                  width="22"
                  height="12"
                  viewBox="0 0 22 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.36 1.00128L20.3587 0L10.68 9.67872L1.00128 0L0 1.00128L10.68 11.6813L21.36 1.00128Z"
                    fill="#FD3C40"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="sellers-products">
          <h3>Seller's products</h3>
          <div className="products">
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
