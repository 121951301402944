import "./style.css";
import productTestImage from "../../assets/images/product.png"
import axios from "axios";
import config from "../../../config";
import { useHistory } from "react-router-dom";

export default function Seller({name, image, email,id, index}) {
    const history = useHistory();

    let rediretSellerSingle = (id) => {
        localStorage.setItem("SellerId", JSON.stringify(id));
        history.push("/seller");

    }

    return (
        <div className="product pointer" key={index} onClick={() => {
            rediretSellerSingle(id)
        }}>
            <img src={image} alt="product"/>
            <p className='product-title'>{name}</p>
        </div>
    );
}
