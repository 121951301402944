import './style.css'
import logo from '../../assets/images/logo.png'
import { NavLink } from "react-router-dom";
import {useEffect, useState} from "react";

export default function Header() {
    let getToken = localStorage.getItem("token");
    let cardProducts = JSON.parse(localStorage.getItem("CardProducts"));
    let [cardItemQuantity,setCardItemQuantity] = useState();
    useEffect(() => {
        if (cardProducts){
            if (cardProducts.length > 0){
                setCardItemQuantity(cardProducts.length)
            }else{
                setCardItemQuantity(0)
            }
        }
    },[])

    return(
        <div className='header'>
                <div className='size'>
                <div className='logo'>
                   <NavLink to='/'> <img src={logo} alt='logo' /></NavLink>
                </div>
                <div className='search-form'>
                    <input type='text' placeholder='Search For Sellers, Brands & Categories' />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.115 14.894L14.053 14.841L11.707 12.554C10.4225 13.5587 8.83775 14.1028 7.207 14.099C5.31729 14.1174 3.49739 13.3857 2.14637 12.0643C0.795347 10.7429 0.0234733 8.93966 0 7.05C0.0234733 5.16007 0.795452 3.35659 2.14666 2.035C3.49787 0.713408 5.31802 -0.0184156 7.208 -1.87336e-06C9.09815 -0.018682 10.9186 0.713023 12.27 2.03464C13.6214 3.35626 14.3935 5.1599 14.417 7.05C14.4149 8.8217 13.7351 10.5255 12.517 11.812L14.835 14.073C14.8864 14.1229 14.9273 14.1826 14.9552 14.2485C14.9831 14.3145 14.9975 14.3854 14.9975 14.457C14.9975 14.5286 14.9831 14.5995 14.9552 14.6655C14.9273 14.7314 14.8864 14.7911 14.835 14.841C14.7398 14.9332 14.6153 14.9891 14.4831 14.9987C14.351 15.0084 14.2196 14.9714 14.112 14.894H14.115ZM1.11 7.05C1.12978 8.64923 1.78314 10.1753 2.92673 11.2934C4.07032 12.4115 5.61073 13.0303 7.21 13.014C8.80927 13.0303 10.3497 12.4115 11.4933 11.2934C12.6369 10.1753 13.2902 8.64923 13.31 7.05C13.2902 5.45077 12.6369 3.92469 11.4933 2.8066C10.3497 1.68851 8.80927 1.06972 7.21 1.086C5.61073 1.06972 4.07032 1.68851 2.92673 2.8066C1.78314 3.92469 1.12978 5.45077 1.11 7.05Z" fill="#200E32"/>
                    </svg>
                </div>
                <div className='header-icons'>

                <NavLink to={"/"}>
                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.664 1.14799C15.6687 1.17227 14.6929 1.42908 13.8147 1.89791C12.9364 2.36674 12.18 3.03457 11.606 3.84799C11.0329 3.03522 10.2777 2.36776 9.40071 1.89894C8.52368 1.43013 7.54914 1.17296 6.55498 1.14799C3.70798 1.14799 0.682983 3.42699 0.682983 7.64799C0.682983 11.988 4.29598 15.017 6.93598 17.23C7.93598 18.07 8.66798 18.664 9.26498 19.148C9.945 19.6657 10.5885 20.2297 11.191 20.836C11.2441 20.8936 11.3086 20.9396 11.3803 20.9711C11.4521 21.0026 11.5296 21.0188 11.608 21.0188C11.6863 21.0188 11.7639 21.0026 11.8356 20.9711C11.9074 20.9396 11.9719 20.8936 12.025 20.836C12.9404 19.9245 13.9185 19.0781 14.952 18.303L16.272 17.237C18.457 15.464 22.529 12.168 22.529 7.606C22.528 3.41 19.508 1.14799 16.664 1.14799ZM6.55498 2.409C7.49453 2.45223 8.40724 2.73599 9.20568 3.23309C10.0041 3.73019 10.6615 4.42397 11.115 5.24799C11.1704 5.32661 11.2438 5.39076 11.3292 5.43504C11.4146 5.47932 11.5093 5.50244 11.6055 5.50244C11.7016 5.50244 11.7964 5.47932 11.8818 5.43504C11.9671 5.39076 12.0406 5.32661 12.096 5.24799C12.5502 4.42208 13.209 3.72676 14.0093 3.22874C14.8096 2.73072 15.7244 2.44672 16.666 2.40399C17.3198 2.42368 17.9628 2.57554 18.5563 2.85041C19.1498 3.12528 19.6815 3.51748 20.1194 4.00337C20.5572 4.48926 20.8922 5.05877 21.104 5.67759C21.3159 6.2964 21.4002 6.95171 21.352 7.604C21.352 11.239 18.36 13.966 15.552 16.236L14.232 17.3C12.861 18.406 12.195 18.945 11.599 19.522C11.164 19.109 10.681 18.722 9.97398 18.145C9.40298 17.682 8.66798 17.086 7.65598 16.234C5.20798 14.185 1.85598 11.377 1.85598 7.647C1.86198 4.248 4.28298 2.409 6.55498 2.409Z" fill="black" stroke="black" strokeWidth="0.5"/>
                    </svg>
                    <div className="red-circle">
                        0
                    </div>
                </NavLink>
                <NavLink to={"/cart"}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.6351 4.9C21.5609 4.79053 21.4611 4.70092 21.3443 4.63903C21.2275 4.57713 21.0973 4.54485 20.9651 4.545L5.01905 4.581L3.69005 0.556C3.63658 0.394065 3.53335 0.253139 3.39509 0.153305C3.25683 0.0534702 3.09059 -0.000179533 2.92005 4.51379e-07H1.61505C1.50868 4.49794e-07 1.40335 0.0209517 1.30508 0.061658C1.20681 0.102364 1.11751 0.162028 1.0423 0.237244C0.967082 0.312459 0.907418 0.401753 0.866712 0.500027C0.826005 0.598301 0.805054 0.70363 0.805054 0.81C0.805054 0.916371 0.826005 1.0217 0.866712 1.11997C0.907418 1.21825 0.967082 1.30754 1.0423 1.38276C1.11751 1.45797 1.20681 1.51764 1.30508 1.55834C1.40335 1.59905 1.50868 1.62 1.61505 1.62H2.33405L3.65405 5.627L3.66705 5.663L5.69105 11.8L3.17505 16.49C3.10902 16.6135 3.07612 16.7519 3.07957 16.8919C3.08301 17.0319 3.12268 17.1686 3.1947 17.2887C3.26672 17.4087 3.36864 17.5081 3.49051 17.577C3.61238 17.646 3.75004 17.6821 3.89005 17.682H18.1301C18.2363 17.682 18.3415 17.6611 18.4396 17.6204C18.5378 17.5798 18.627 17.5202 18.7021 17.4451C18.7772 17.3699 18.8368 17.2807 18.8775 17.1826C18.9181 17.0844 18.9391 16.9792 18.9391 16.873C18.9391 16.7668 18.9181 16.6616 18.8775 16.5634C18.8368 16.4653 18.7772 16.3761 18.7021 16.301C18.627 16.2258 18.5378 16.1662 18.4396 16.1256C18.3415 16.0849 18.2363 16.064 18.1301 16.064H5.24105L7.05105 12.69H18.3511C18.5129 12.6903 18.671 12.642 18.805 12.5512C18.9389 12.4604 19.0424 12.3314 19.1021 12.181L21.7121 5.657C21.7618 5.53408 21.7807 5.40088 21.7673 5.26896C21.7539 5.13705 21.7085 5.0104 21.6351 4.9ZM17.8061 11.068H7.15005L5.55005 6.198L19.7681 6.166L17.8061 11.068Z" fill="black"/>
                        <path d="M5.89608 19.186C5.68223 19.1858 5.47313 19.249 5.29523 19.3677C5.11733 19.4863 4.97863 19.6551 4.89666 19.8527C4.81469 20.0502 4.79314 20.2675 4.83474 20.4773C4.87634 20.687 4.97921 20.8798 5.13035 21.0311C5.28149 21.1823 5.47411 21.2853 5.68383 21.3271C5.89355 21.3689 6.11095 21.3476 6.30854 21.2658C6.50612 21.184 6.675 21.0455 6.79383 20.8677C6.91266 20.6899 6.97608 20.4809 6.97608 20.267C6.97621 20.1251 6.94837 19.9845 6.89416 19.8534C6.83994 19.7222 6.76042 19.6031 6.66012 19.5027C6.55982 19.4023 6.4407 19.3226 6.30961 19.2683C6.17851 19.214 6.03799 19.186 5.89608 19.186Z" fill="black"/>
                        <path d="M15.7511 19.186C15.5373 19.186 15.3282 19.2494 15.1505 19.3682C14.9727 19.4869 14.8342 19.6558 14.7523 19.8533C14.6705 20.0509 14.6491 20.2682 14.6908 20.4779C14.7325 20.6876 14.8355 20.8802 14.9867 21.0314C15.1378 21.1825 15.3305 21.2855 15.5402 21.3272C15.7499 21.3689 15.9672 21.3476 16.1648 21.2657C16.3623 21.1839 16.5311 21.0453 16.6499 20.8675C16.7686 20.6898 16.832 20.4808 16.832 20.267C16.8322 20.125 16.8043 19.9844 16.75 19.8531C16.6957 19.7219 16.6162 19.6027 16.5158 19.5023C16.4154 19.4019 16.2961 19.3223 16.1649 19.268C16.0337 19.2137 15.8931 19.1859 15.7511 19.186Z" fill="black"/>
                    </svg>
                    <div className="red-circle">
                        {
                            cardItemQuantity
                        }
                    </div>
                </NavLink>
                 <NavLink to={getToken ? 'editProfile' : 'signUp'}>
                     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.2731 18.291C19.1105 16.8813 18.6308 15.5266 17.8699 14.3286C17.1091 13.1307 16.0869 12.1206 14.8801 11.374C14.7757 11.3099 14.6596 11.2669 14.5386 11.2476C14.4176 11.2283 14.294 11.233 14.1748 11.2614C14.0556 11.2899 13.9432 11.3416 13.844 11.4135C13.7448 11.4854 13.6607 11.5761 13.5966 11.6805C13.5324 11.7849 13.4895 11.9009 13.4702 12.0219C13.4508 12.1429 13.4556 12.2666 13.484 12.3857C13.5125 12.5049 13.5641 12.6173 13.636 12.7166C13.7079 12.8158 13.7987 12.8998 13.9031 12.964C14.8688 13.5606 15.6868 14.3681 16.2957 15.3261C16.9047 16.284 17.2887 17.3674 17.4191 18.495C17.4444 18.7231 17.5528 18.9339 17.7236 19.0872C17.8944 19.2404 18.1156 19.3254 18.345 19.326C18.3785 19.3257 18.4119 19.3238 18.4451 19.32C18.5671 19.3071 18.6854 19.2701 18.7931 19.2114C18.9008 19.1526 18.9959 19.0731 19.0728 18.9775C19.1498 18.8819 19.207 18.772 19.2414 18.6542C19.2758 18.5364 19.2865 18.413 19.2731 18.291Z" fill="black"/>
                        <path d="M15.5971 6.271C15.5992 5.45514 15.4229 4.64868 15.0808 3.90805C14.7386 3.16742 14.2387 2.5105 13.6161 1.98326C12.9935 1.45602 12.2632 1.07119 11.4763 0.85569C10.6894 0.640195 9.8649 0.59924 9.06053 0.735692C8.25616 0.872144 7.49132 1.18271 6.81952 1.64565C6.14772 2.10859 5.58519 2.71274 5.17129 3.41581C4.75738 4.11889 4.5021 4.90391 4.4233 5.71596C4.34451 6.52801 4.4441 7.34746 4.71511 8.117C4.75536 8.23263 4.81799 8.3392 4.89942 8.43062C4.98086 8.52205 5.0795 8.59653 5.18973 8.64984C5.29995 8.70314 5.41959 8.73421 5.54182 8.74127C5.66405 8.74833 5.78648 8.73125 5.90211 8.691C6.01774 8.65075 6.1243 8.58812 6.21573 8.50668C6.30715 8.42525 6.38164 8.3266 6.43494 8.21638C6.48824 8.10615 6.51931 7.98651 6.52638 7.86428C6.53344 7.74205 6.51636 7.61963 6.47611 7.504C6.21455 6.75655 6.19746 5.94529 6.4273 5.18749C6.65715 4.42969 7.12203 3.76461 7.75474 3.2884C8.38745 2.81219 9.1552 2.54953 9.94701 2.53837C10.7388 2.52721 11.5137 2.76815 12.1595 3.22634C12.8054 3.68454 13.2888 4.33625 13.54 5.08728C13.7911 5.8383 13.7968 6.64972 13.5564 7.40424C13.316 8.15876 12.8419 8.81729 12.2026 9.28463C11.5633 9.75196 10.792 10.0039 10.0001 10.004C7.70455 9.99972 5.48831 10.8435 3.77684 12.3734C2.06537 13.9032 0.979289 16.0113 0.727107 18.293C0.700167 18.5391 0.772036 18.7858 0.92692 18.979C1.0818 19.1721 1.30703 19.2958 1.55311 19.323C1.58631 19.3267 1.61969 19.3287 1.65311 19.329C1.88271 19.3289 2.10419 19.244 2.27508 19.0906C2.44596 18.9373 2.55423 18.7263 2.57911 18.498C2.77986 16.6724 3.64818 14.9853 5.01722 13.761C6.38627 12.5366 8.15946 11.8614 9.99611 11.865C11.4805 11.8653 12.9042 11.2762 13.9544 10.2273C15.0047 9.17834 15.5955 7.75534 15.5971 6.271Z" fill="black"/>
                    </svg>
                 </NavLink>
                </div>
            </div>
        </div>
    )
}
