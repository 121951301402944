import "./style.css";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import config from "../../../config";
import validator from "validator";

export default function NewPassword() {
    const history = useHistory();
    let getVerificationEmail = localStorage.getItem("verificationEmail");
    const [passwordError, setPasswordError] = useState('');

    let [register, setRegister] = useState({
        email: getVerificationEmail,
        password: "",
        password_confirmation: "",
    });
    let handlePasswordInput = (e) => {
        setRegister({
            ...register,
            [e.target.name]: e.target.value,
        });
    }



    let resetFormSend = () => {
        console.log(register)
        axios.post(`${config.url}/reset`, register, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                setPasswordError('');

                console.log(res)
                if (res.data.error) {
                    if (res.data.error.password) {
                        setPasswordError(res.data.error.password);
                    }
                } else {
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <div className="newPassword">
            <Header/>
            <Navigation/>
            <div className='content'>
                <div></div>
                <div className='form'>
                    <h2 className='pageTitle'>New password</h2>
                    <input type="password" placeholder="Password"
                           name="password"

                           onChange={handlePasswordInput}
                   className={passwordError && passwordError !== true ? 'errorInput' : ''}
                    />

                    <input type="password" placeholder="Re-enter password"
                           name="password_confirmation"
                           onChange={handlePasswordInput}
                           className={passwordError && passwordError !== true ? 'errorInput' : ''}
                    />
                    <span
                        className="error"
                        style={{ display: passwordError && passwordError !== true ? "block" : "none" }}
                    >
                    {passwordError}
                  </span>
                    <button className='signup' onClick={resetFormSend}>SIGN UP</button>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
